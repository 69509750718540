import dropdown from "../assets/dropdown.webp"
import close from "../assets/close.webp"
import {useEffect, useState} from "react";
import outgoings_api from "../api/outgoings";

const AddOutgoingForm = (props) => {

    const periods = ['Yearly', 'Monthly', 'Biweekly', 'Weekly', 'Daily']

    const updateDisplay = props.updateDisplay
    const refreshTransactions = props.refreshTransactions

    const [display, setDisplay] = useState()
    const [accounts, setAccounts] = useState()

    const [bank, setBank] = useState()
    const [payee, setPayee] = useState()
    const [amount, setAmount] = useState()
    const [periodicity, setPeriodicity] = useState(periods[0])
    const [date, setDate] = useState()
    const [description, setDescription] = useState()


    const Submit = (ev) => {
        if (!CheckData) return;
        let formData = {
            "bank": bank,
            "payee": payee,
            "amount": amount,
            "periodicity": periodicity,
            "date": date,
            "description": description ? description : ""
        }
        let response = outgoings_api.postRegularTransaction(formData);
        response.then(
            () => {
                refreshTransactions()
                updateDisplay(ev)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        setAccounts(props.accounts)
    }, [props.accounts])

    useEffect(() => {
        setDisplay(props.display)
        setBank(null)
        setPayee(null)
        setAmount(null)
        setPeriodicity(periods[0])
        setDate(null)
        setDescription(null)
    }, [props.display])

    const CheckData = () => {
        let bankCheck = bank && bank
        let payeeCheck = payee && payee.length > 0
        let amountCheck = amount && amount.length > 0
        let frequencyCheck = periodicity && periodicity.length > 2
        let dateCheck = !(!date)
        return [bankCheck, payeeCheck, amountCheck, frequencyCheck, dateCheck].every(Boolean);
    }

    return (
        <>
            {display && display ?
                <>
                    <div className="page-mask"/>
                    <div className="add-transaction-form-container">
                        <div className="add-transaction-form-top-container">
                            <div className="add-transaction-form-top">
                                <div className="add-transaction-form-heading-container">
                                    <div className="add-transaction-form-heading">
                                        New outgoing
                                    </div>
                                </div>
                                <button onClick={updateDisplay} className="add-transaction-form-close" id={"toggle"}>
                                    <img src={close} id={"toggle"} alt="close form icon"/>
                                </button>
                            </div>
                            <div className="add-transaction-form-description">
                                Is there something missing from your account that you want to share on your Executor report?
                                You can manually add your outgoings details here.
                            </div>
                        </div>

                        <div className="add-transaction-form-row-container">
                            <div className="add-transaction-form-row-label">
                                Bank
                            </div>
                            <div className="add-transaction-form-row-input">
                                <select className="add-transaction-form-dropdown"
                                        onChange={(ev) => setBank(ev.target.value)}>
                                    <option className="add-transaction-form-dropdown-items" selected={true} disabled>
                                        Select an Account
                                    </option>
                                    {accounts && accounts.map((account, idx) => {
                                        return (
                                            <option className="add-transaction-form-dropdown-items" value={account.id}>
                                                {account.provider + ' ' + account.name}
                                            </option>
                                        )
                                    })}
                                </select>
                                <img src={dropdown}/>
                            </div>
                        </div>
                        <div className="add-transaction-form-row-container">
                            <div className="add-transaction-form-row-label">
                                Payee
                            </div>
                            <div className="add-transaction-form-row-input">
                                <input id="payee" type="text"
                                       onChange={(ev) => {
                                           setPayee(ev.target.value)
                                       }}/>
                            </div>
                        </div>
                        <div className="add-transaction-form-row-container">
                            <div className="add-transaction-form-row-label">
                                Amount
                            </div>
                            <div className="add-transaction-form-row-input">
                                <input id="amount" type="text"
                                       onChange={(ev) => setAmount(ev.target.value)}/>
                            </div>
                        </div>
                        <div className="add-transaction-form-row-container">
                            <div className="add-transaction-form-row-label">
                                Frequency
                            </div>
                            <div className="add-transaction-form-row-input">
                                <select formControlName="periodicity" className="add-transaction-form-dropdown"
                                        onChange={(ev) => setPeriodicity(ev.target.value)}>
                                    {periods && periods.map((period, idx) => {
                                        return (
                                            <option className="add-transaction-form-dropdown-items" value={period}>
                                                {period}
                                            </option>
                                        )
                                    })}
                                </select>
                                <img src={dropdown}/>
                            </div>
                        </div>
                        <div className="add-transaction-form-row-container">
                            <div className="add-transaction-form-row-label">
                                Last transaction date
                            </div>
                            <div className="add-transaction-form-row-input">
                                <input type="date" id="date" pattern="\d{4}-\d{2}-\d{2}"
                                       onChange={(ev) => setDate(ev.target.value)}/>
                            </div>
                        </div>
                        <div className="add-transaction-form-row-container-large">
                            <div className="add-transaction-form-row-label-large">
                                Additional notes
                            </div>
                            <div className="add-transaction-form-row-input-large">
                            <textarea id="description"
                                      onChange={(ev) => setDescription(ev.target.value)}/>
                            </div>
                        </div>
                        <div className="add-transaction-form-add-button-container">
                            <button onClick={Submit} className="add-transaction-form-add-button" id="toggle" disabled={!CheckData()}>
                                Save outgoing
                            </button>
                        </div>
                    </div>
                </>
                :
                null
            }
        </>
    )
}

export default AddOutgoingForm;