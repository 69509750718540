import {useNavigate} from "react-router-dom";

const HomepageGetStartedBanner = () => {
    const navigate = useNavigate()

    return (
        <div className="home-page-get-started-get-started-banner">
            <div className="get-started-banner-title">
                Get started for free
            </div>
            <div className="get-started-banner-description">
                No commitment required, join for free today!
            </div>
            <div tabIndex="0" className="get-started-banner-button link" onClick={(ev) => {
                navigate("/register")}}>
                Sign up
            </div>
        </div>
    )
}

export default HomepageGetStartedBanner