import http_api from "./http/http_api";
import http from "./http/http";

const verifyEmail = (body) => http.post("/auth/verify/email", body);
const verifyMobile = (body) => http.post("/auth/verify/phone", body);
const verify2FA = (body) => http.post("/auth/verify/2fa", body);
const enable2FA = (body) => http.post("/auth/enable/2fa", body);
const update2FA = (body) => http_api.post("/update/2fa", body);
const resendEmail = (body) => http.post("/auth/resend/email/verification/code", body);
const resendMobile = (body) => http.post("/auth/resend/phone/verification/code", body);

const resend2FA = (body) => http.post("/auth/resend/2fa/verification/code", body);

const registerUser = (body) => http.post("/auth/signup", body);
const loginUser = (body) => http.post("/login", body)
const logoutUser = (body) => http.post("/auth/logout", body);
const forgotPassword = (body) => http.post("/auth/reset/link", body);
const resetVerify = (body) => http.post("/auth/reset/verify", body);
const resetPassword = (body) => http.post("/auth/reset/password", body);

const getProfile = () => http_api.get("/44968d3ddb237d5c8c9320019/profile")

const updateUser = (data) => http_api.put("/44968d3ddb237d5c8c9320019/update/profile", data)

const sendFeedback = (data) => http_api.post("/44968d3ddb237d5c8c9320019/create/feedback", data)

const inviteUser = (data) => http_api.post("/44968d3ddb237d5c8c9320019/send_invite", data)

const usersSubscription = () => http_api.get("/44968d3ddb237d5c8c9320019/users-subscription")

export default {
    registerUser,
    verifyEmail,
    verifyMobile,
    verify2FA,
    enable2FA,
    update2FA,
    loginUser,
    getProfile,
    logoutUser,
    updateUser,
    resendEmail,
    resendMobile,
    resend2FA,
    sendFeedback,
    inviteUser,
    forgotPassword,
    resetVerify,
    resetPassword,
    usersSubscription
}