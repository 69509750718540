import {useEffect, useState} from "react";

import RegisterLandingPage from "../../components/subpages/RegisterLandingPage";
import RegisterEmailVerification from "../../components/subpages/RegisterEmailVerification";
import RegisterMobileVerification from "../../components/subpages/RegisterMobileVerification";
import Register2Factor from "../../components/subpages/Register2Factor";
import RegisterNextOfKin from "../../components/subpages/RegisterNextOfKin";
import RegisterAccessNow from "../../components/subpages/RegisterAccessNow";

import sanctum_api from "../../api/csrf";
import users_api from "../../api/users";
import auth from "../../api/auth";
import RegisterPaywall from "../../components/subpages/RegisterPaywall";
import RegisterComplete from "../../components/subpages/RegisterComplete";
import {useSearchParams} from "react-router-dom";


const Register = () => {
    const LANDING_PAGE = 0
    const EMAIL_VERIFICATION = 1
    const MOBILE_VERIFICATION = 2
    const TWO_STEP_VERIFICATION = 3
    const NEXT_OF_KIN = 4
    const ACCESS_NOW = 5
    const PAYWALL = 6
    const COMPLETE = 7

    const [registerSubpage, setRegisterSubpage] = useState(0)

    const [email, setEmail] = useState("")
    const [mobileNumber, setMobileNumber] = useState("")
    const [mobileCode, setMobileCode] = useState();
    const [password, setPassword] = useState("")
    const [nextOfKinEmail, setNextOfKinEmail] = useState("")

    const [params, setParams] = useSearchParams();


    const [plus, setPlus] = useState(false)

    const nextSubpage = () => {
        setRegisterSubpage(registerSubpage + 1)
    }

    const setSubpage = (x) => {
        if (!x && x !== 0) return;
        setRegisterSubpage(x)
    }

    const UpdateEmail = (x) => {
        if (!x) return
        setEmail(x)
    }

    const UpdateMobile = (x) => {
        if (!x) return
        setMobileNumber(x)
    }

    const UpdatePassword = (x) => {
        if (!x) return
        setPassword(x)
    }

    const UpdateNextOfKinEmail = (x) => {
        if (!x) return
        setNextOfKinEmail(x)
    }

    const UpdateMobileCode = (code) => {
        setMobileCode(code);
    }

    useEffect(() => {
        if (registerSubpage === 3) {
            LoginUser()
        }
    }, [registerSubpage])

    useEffect(() => {
        if (!params.get("page")) return;

        switch (params.get("page")){
            default:
                setParams(params.delete("page"));
                break;
            case "complete":
                setRegisterSubpage(COMPLETE);
                setPlus(true)
                setParams(params.delete("page"))
                break;
            case "subscription":
                setRegisterSubpage(PAYWALL);
                setPlus(false)
                setParams(params.delete("page"))
                break;
        }
    }, [params.get("page")])


    const LoginUser = () => {
        let token_response = sanctum_api.getToken();
        token_response.then(
            () => {
                let formData = {
                    "email": email,
                    "password": password,
                }
                let response = users_api.loginUser(formData);
                response.then(
                    response => {
                        if(response.data.error) {
                            auth.logout()
                        }
                        else{
                            localStorage.removeItem("userState");
                            localStorage.setItem("userState",JSON.stringify(response.data.user));
                        }
                    }
                ).catch((err) => {})
            }
        ).catch((err) => {})
    }

    return (
        <>
            <div className="register-page-container">
                <RegisterLandingPage
                    show={LANDING_PAGE === registerSubpage}
                    next={nextSubpage.bind(this)}
                    setEmail={UpdateEmail.bind(this)}
                    setMobile={UpdateMobile.bind(this)}
                    setPassword={UpdatePassword.bind(this)}
                />
                <RegisterEmailVerification
                    show={EMAIL_VERIFICATION === registerSubpage}
                    page={registerSubpage && registerSubpage}
                    email={email && email}
                    next={nextSubpage.bind(this)}
                    setPage={setSubpage.bind(this)}
                />
                <RegisterMobileVerification
                    show={MOBILE_VERIFICATION === registerSubpage}
                    page={registerSubpage && registerSubpage}
                    email={email}
                    updateMobileCode={UpdateMobileCode.bind(this)}
                    next={nextSubpage.bind(this)}
                    setPage={setSubpage.bind(this)}
                />
                <Register2Factor
                    show={TWO_STEP_VERIFICATION === registerSubpage}
                    page={registerSubpage && registerSubpage}
                    next={nextSubpage.bind(this)}
                    email={email}
                    code={mobileCode}
                />
                <RegisterNextOfKin
                    show={NEXT_OF_KIN === registerSubpage}
                    page={registerSubpage && registerSubpage}
                    updateNextOfKin={UpdateNextOfKinEmail.bind(this)}
                    next={nextSubpage.bind(this)}
                    setPage={setSubpage.bind(this)}
                />
                <RegisterAccessNow
                    show={ACCESS_NOW === registerSubpage}
                    page={registerSubpage && registerSubpage}
                    nextOfKinEmail={nextOfKinEmail && nextOfKinEmail}
                    next={nextSubpage.bind(this)}
                />
                <RegisterPaywall
                    show={PAYWALL === registerSubpage}
                    page={registerSubpage && registerSubpage}
                    next={nextSubpage.bind(this)}
                />
                <RegisterComplete
                    show={COMPLETE === registerSubpage}
                    page={registerSubpage && registerSubpage}
                    plus={plus}
                />
            </div>
        </>
    )
}

export default Register