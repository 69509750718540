import greg from "../../assets/greg_gormley.webp";
import quote from "../../assets/quote.webp"

import tech_crunch from "../../assets/pressMentions/tech-crunch.webp"
import bloomberg from "../../assets/pressMentions/bloomberg.webp"
import gizmodo from "../../assets/pressMentions/gizmodo.webp"
import forbes from "../../assets/pressMentions/forbes.webp"

const HomepageOurStory = () => {
    return (
        <div className="home-page-our-story-container">
            <a className="anchor" id="our-story" />
            <div className="home-page-our-story-card-container">
                    <div className="home-page-our-story-card">
                        <div className="home-page-our-story-title">
                            Our founder’s story
                        </div>
                        <div className="home-page-our-story-content-container">
                            <div className="home-page-our-story-text-container">
                                    <div className="home-page-our-story-text-sub-container">
                                        <img className="home-page-our-story-text-quote" alt="Our story quote image" src={quote} />
                                        <div className="home-page-our-story-text">
                                            Riding my motorbike in June 2018, I had a head-on collision with a car. I ended up being
                                            helicoptered to hospital where I underwent 3 major operations.
                                        </div>
                                        <div className="home-page-our-story-text">
                                            Had I been killed that day, my family would’ve had a nightmare. No idea of bank accounts,
                                            the life assurance policies, pensions or even my direct debits.
                                        </div>
                                        <div className="home-page-our-story-text">
                                            This is why we created Snapshot. organise your life in 3 minutes, don’t do it for you
                                            - do it for your family.
                                        </div>
                                    </div>
                                </div>
                            <div className="home-page-our-story-image-container">
                                <img className="home-page-our-story-image" src={greg} alt="Our story image"/>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="home-page-our-story-card-backdrop-container"></div>

            <div className="home-page-mentioned-press-container">
                <div className="home-page-mentioned-press">
                    We may have been mentioned in the press
                </div>
                <div className="home-page-mentioned-press-logos-container">
                    <div className="row">
                        <div className="home-page-press-logo col-sm-3">
                            <img src={tech_crunch} alt="Tech crunch logo"/>
                        </div>
                        <div className="home-page-press-logo col-sm-3">
                            <img src={bloomberg} alt="Bloomberg logo"/>
                        </div>
                        <div className="home-page-press-logo col-sm-3">
                            <img src={gizmodo} alt="Gizmodo logo"/>
                        </div>
                        <div className="home-page-press-logo col-sm-3">
                            <img src={forbes} alt="Forbes logo"/>
                        </div>
                    </div>
                </div>
                <div className="home-page-mentioned-press-contact">
                    If you want to speak to one of our spokespeople or find out more about Snapshot contact the press
                    team at press@snapshot.life
                </div>
            </div>
            <div className="home-page-separator"></div>
        </div>
    )
}

export default HomepageOurStory