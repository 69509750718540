import {useState} from "react";
import {useNavigate} from "react-router-dom";

import LoginRegisterSideImage from "../LoginRegisterSideImage";

import sanctum_api from "../../api/csrf";
import users_api from "../../api/users";
import auth from "../../api/auth";

import eye_slash from "../../assets/eye-slash.webp";
import eye from "../../assets/eye.webp";


const RegisterLandingPage = (props) => {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState("")

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const nextSubpage = props.next
    const UpdateEmail = props.setEmail
    const UpdateMobile = props.setMobile
    const UpdatePassword = props.setPassword

    const handleRegister = (event) => {
        setError("")
        event.preventDefault();
        if (CheckPassword()) {
            SplitName();
            return true;
        }
        else {
            setError("Please ensure your passwords match")
        }
    }

    const CheckPassword = () => {
        return password === confirmPassword;
    }

    const SplitName = () => {
        let names = name.split(" ")
        if (names.length < 2 || names[1] === "") {
            setError("Please include a First and a Last name")
            return
        }
        let firstName;
        let lastName = "";
        names.map((name, idx) => {
            if (idx === 0) firstName = name
            else lastName += name + " "
        })
        lastName = lastName.trim()
        RegisterUser(firstName, lastName);
    }

    const RegisterUser = (firstName, lastName) => {
        let token_response = sanctum_api.getToken();
        token_response.then(
            () => {
                let formData = {
                    "email": email,
                    "first_name": firstName,
                    "last_name": lastName,
                    "password": password,
                    "password_confirmation": confirmPassword,
                    "phone": mobileNumber
                }
                let response = users_api.registerUser(formData);
                response.then(
                    response => {
                        if(response.data.error) {
                            auth.logout()
                            setError(response.data.error)
                        }
                        else{
                            UpdateEmail(email)
                            UpdateMobile(mobileNumber)
                            UpdatePassword(password)
                            nextSubpage()
                        }
                    }
                ).catch((err) => {
                    if (err.response.status === 422){
                        // Back end validation failed
                        setError(err.response.data.error);
                    }
                    else {
                        setError("Registration failed")
                    }
                    console.log(err)
                })
            }
        ).catch((err) => {
            setError("Registration failed")
            console.log(err)
        })
    }

    const handlePasswordClick = (ev) => {
        if (!ev) return;
        setShowPassword(!showPassword);
    }

    const handleConfirmPasswordClick = (ev) => {
        if (!ev) return;
        setShowConfirmPassword(!showConfirmPassword);
    }

    return (
        <div className="register-subpage-container" style={!props.show ? {display: "none"} : {}}>
            <LoginRegisterSideImage />
            <div className="register-page-form-container">
                <form name="register-form" className="register-form" onSubmit={handleRegister} autocomplete="off">
                    <div className="register-page-title">
                        Let’s get you organised.
                    </div>
                    <div className="register-page-description">
                        With just a few clicks, you can ensure your loved ones are taken care of.
                    </div>
                    <div className="register-page-subtitle">
                        Your details
                    </div>
                    <div className="register-page-inputs-container">
                        <div className="register-form-element-container">
                            <div className="register-form-element-label">Name*</div>
                            <input type="text" required autocomplete="off"
                                   onChange={(ev) => {setName(ev.target.value)}}/>
                        </div>

                        <div className="register-form-element-container">
                            <div className="register-form-element-label">Email*</div>
                            <input type="email" required autocomplete="off"
                                   onChange={(ev) => {setEmail(ev.target.value)}}/>
                        </div>

                        <div className="register-form-element-container">
                            <div className="register-form-element-label">Mobile number*</div>
                            <input type="text" required autocomplete="off"
                                   onChange={(ev) => {setMobileNumber(ev.target.value)}}/>
                        </div>

                        <div className="register-form-element-password-container">
                            <div className="register-form-element-label">Password*</div>
                            <input type={showPassword ? "text" : "password"} required autocomplete="off"
                                   onChange={(ev) => {setPassword(ev.target.value)}} />
                            <img tabIndex="0" className="link" src={showPassword ? eye_slash : eye}
                                 alt="Show/Hide Password" onClick={handlePasswordClick}/>
                        </div>

                        <div className="register-form-element-password-container">
                            <div className="register-form-element-label">Confirm password*</div>
                            <input type={showConfirmPassword ? "text" : "password"} required autocomplete="off"
                                   onChange={(ev) => {setConfirmPassword(ev.target.value)}} />
                            <img tabIndex="0" className="link" src={showConfirmPassword ? eye_slash : eye}
                                 alt="Show/Hide Password" onClick={handleConfirmPasswordClick}/>
                        </div>

                        <div className="register-error-message">
                            {error && error}
                        </div>
                    </div>
                    <div className="register-form-submit-container">
                        <input type="submit" value="Get started" />
                    </div>
                    <div className="login-form-info-container">
                        <div className="login-page-no-account">
                            Already have an account?
                            <div tabIndex="0" className="register-now-link link" onClick={(ev) => navigate("/login")}>
                                Sign in
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )

}

export default RegisterLandingPage