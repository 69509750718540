import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import sanctum_api from "../../api/csrf";
import users_api from "../../api/users";

import eyeslash from "../../assets/eye-slash.webp";
import eye from "../../assets/eye.webp";
import auth from "../../api/auth";

import LoginRegisterSideImage from "../../components/LoginRegisterSideImage";

const Login = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState(() => {
        const savedEmail = localStorage.getItem("login_email")
        console.log(savedEmail)
        return savedEmail || ("");
    })

    const [password, setPassword] = useState("")

    const [checkbox, setCheckbox] = useState(null);

    const [showPassword, setShowPassword] = useState(false)

    const [error, setError] = useState("")

    const handleLogin = (ev) => {
        ev.preventDefault();
        LoginUser();
        return true;
    }

    const handlePasswordClick = (ev) => {
        if (!ev) return;
        setShowPassword(!showPassword);
    }

    const LoginUser = () => {
        let token_response = sanctum_api.getToken();
        token_response.then(
            () => {
                let formData = {
                    "email": email,
                    "password": password,
                }
                let response = users_api.loginUser(formData);
                response.then(
                    response => {
                        if(response.data.error) {
                            auth.logout()
                            setError(response.data.error)
                        }
                        else{
                            localStorage.removeItem("userState");
                            localStorage.setItem("userState",JSON.stringify(response.data.user));
                            if (response.data.user.mfa_enabled){
                                navigate("/verify2fa");
                            }
                            else {
                                navigate("/dashboard");
                            }
                        }

                    }
                ).catch((err) => {
                    setError(err)
                })
            }
        ).catch((err) => {
            setError(err)
        })
    }

    const updateCheckbox = () => {
        if (document.getElementById('remember-check').checked === false) {
            setCheckbox(false)
            localStorage.clear();
        }
        setCheckbox(true)
    }

    useEffect(() => {
        /*if (checkbox === true) {
            localStorage.setItem("login_email", email)
        }*/
    },[checkbox,email])

    return (
        <>
            <div className="login-page-container">
                <LoginRegisterSideImage />
                <div className="login-page-form-container">
                    <form name="login-form" onSubmit={handleLogin}>
                        <div className="login-page-title">
                            Welcome back!
                        </div>
                        <div className="login-page-description">
                            Welcome back to Snapshot! Please sign in below to continue.
                        </div>
                        <div className="login-page-subtitle">
                            Your details
                        </div>
                        <div className="login-page-inputs-container">
                            <div className="login-form-element-container">
                                <div className="login-form-element-label">Email address</div>
                                <input type="email" value={email} required
                                       onChange={(ev) => {setEmail(ev.target.value);updateCheckbox()}}/>
                            </div>

                            <div className="login-form-element-password-container">
                                <div className="login-form-element-label">Password</div>
                                <input type={showPassword ? "text" : "password"} required
                                       onChange={(ev) => {setPassword(ev.target.value)}} />
                                <img tabIndex="0" className="link" src={showPassword ? eyeslash : eye}
                                     alt="Show/Hide Password" onClick={handlePasswordClick}/>
                            </div>
                            <div className="login-page-remember-forget-container">
                                <div className="login-page-remember-container">
                                    <input type="checkbox" id="remember-check" onClick={updateCheckbox}/>
                                    <label className="login-form-element-label" for="remember-check">Remember me</label>
                                </div>
                                <div className="login-page-forgot">
                                    <div tabIndex="0" className="register-now-link link" onClick={(ev) => navigate("/forgot-password")}>
                                        Forgot password ?
                                    </div>
                                </div>
                            </div>

                            <div className="login-error-message">
                                {error && error}
                            </div>
                        </div>
                        <div className="login-form-submit-container">
                            <input type="submit" value="Sign in" />
                        </div>
                        <div className="login-form-info-container">
                            <div className="login-page-no-account">
                                Don’t have an account?
                                <div tabIndex="0" className="register-now-link link" onClick={(ev) => navigate("/register")}>
                                    Register now
                                </div>
                            </div>
                            <div className="login-page-contact-info">
                                support@snapshot.com
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Login