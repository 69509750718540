const formatDateLong = (string_date) => {
    let date = new Date(string_date)
    return date.toLocaleDateString("en-uk",  { year: 'numeric', month: 'long', day: 'numeric' });
}

const formatDate = (string_date) => {
    let date = new Date(string_date)
    return date.toLocaleDateString("en-uk");
}

const formatDateReportHeader = (string_date) => {
    let date = new Date(string_date)
    return date.toLocaleDateString("en-uk",  { year: 'numeric', month: 'long'});
}

export default {
    formatDate,
    formatDateLong,
    formatDateReportHeader
}