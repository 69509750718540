import {useState} from "react";
import public_api from "../../api/public"
import sanctum_api from "../../api/csrf";

const HomepageContact = () => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [message, setMessage] = useState("")
    const [agree, setAgree] = useState(false)
    const [error, setError] = useState("")

    const CheckData = () => {
        let emailCheck = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
        let firstNameCheck = /^[a-z ,.'-]+$/i.test(firstName)
        let lastNameCheck = /^[a-z ,.'-]+$/i.test(lastName)
        let phoneCheck = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(mobileNumber)
        let messageCheck = !!message
        let agreeCheck = !!agree
        return [emailCheck, firstNameCheck, lastNameCheck, phoneCheck, messageCheck, agreeCheck].every(Boolean);
    }

    const sendMessage = () => {
        if (!CheckData()) {
            setError("Please enter valid data")
            return
        }
        submitData()
    }

    const submitData = () => {
        let token_response = sanctum_api.getToken();
        token_response.then(
            () => {
                let formData = {
                    "first_name": firstName,
                    "last_name": lastName,
                    "email": email,
                    "mobile": mobileNumber,
                    "message": message
                }
                let response = public_api.SendGetInTouch(formData);
                response.then(
                    response => {
                        if (!response.data.error) {
                            ClearData()
                            setError("")
                        }
                        else {
                            setError(response.data.error)
                        }
                    }
                ).catch((err) => {
                    console.log(err)
                    setError(err.status)
                })
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    const ClearData = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setMobileNumber("");
        setMessage("")
    }

    return (
        <div className="home-page-contact-container" id="contact">
            <div className="row">
                <div className="home-page-contact-left-container col-sm-6 offset-sm-6">
                    <div className="home-page-contact-left">
                        <div className="home-page-contact-title">
                            Get in touch
                        </div>
                        <div className="home-page-contact-description">
                            Our friendly team would love to hear from you.
                        </div>
                        <div className="home-page-contact-error">
                            {error && error}
                        </div>
                        <div className="home-page-contact-form-container">
                            <div className="home-page-contact-form-row-container row">
                                <div className="col-sm-6">
                                    <div className="contact-form-half-element-container">
                                        <label>First name</label>
                                        <div className="contact-form-element-container">
                                            <input id="firstName" type="text" required onChange={(ev) => {
                                                setFirstName(ev.target.value)
                                            }} value={firstName} placeholder="First name"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="contact-form-half-element-container">
                                        <label>Last name</label>
                                        <div className="contact-form-element-container">
                                            <input id="lastName" type="text" required onChange={(ev) => {
                                                setLastName(ev.target.value)
                                            }} value={lastName} placeholder="Last name"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="home-page-contact-form-row-container">
                                <label>Email address</label>
                                <div className="contact-form-element-container contact">
                                    <input id="email" type="text" required onChange={(ev) => {
                                        setEmail(ev.target.value)
                                    }} value={email} placeholder="you@company.com"/>
                                </div>
                            </div>
                            <div className="home-page-contact-form-row-container">
                                <label htmlFor="mobile">Mobile number</label>
                                <div className="contact-form-element-container">
                                    <input id="mobile" type="text" required onChange={(ev) => {
                                        setMobileNumber(ev.target.value)
                                    }} value={mobileNumber}/>
                                </div>
                            </div>
                            <div className="home-page-contact-form-row-container">
                                <label htmlFor="message">Message</label>
                                <div className="contact-form-message-container">
                                    <textarea id="message" required onChange={(ev) => {
                                        setMessage(ev.target.value)
                                    }} value={message}/>
                                </div>
                            </div>
                            <div className="home-page-contact-form-row-container">
                                <input type="checkbox" className="home-page-contact-form-privacy-tick" id="contact-check" onChange={(ev) => {
                                    setAgree(ev.target.checked)
                                }}/>
                                <label htmlFor="contact-check">You agree to our friendly <u> privacy policy </u> </label>
                            </div>
                            <button className="home-page-contact-form-submit-button" onClick={(ev) => {
                                sendMessage()
                            }} >
                                Send Message
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomepageContact