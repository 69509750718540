import pricing_feature from "../../assets/homepage-pricing-feature.webp"
import {Link} from "react-router-dom";


const HomepagePricing = () => {
    return (
        <div className="home-page-pricing-container" >
            <a className="anchor" id="pricing" />
            <div className="home-page-pricing-title">
                Simple, easy pricing
            </div>
            <div className="home-page-pricing-description">
                Our pricing is designed to be simple and straightforward, with two tiers that cater to your specific
                needs and budget.
            </div>
            <div className="home-page-price-cards-container">
                <div className="row">
                    <div className="home-page-price-card-container col-sm-6">
                        <div className="home-page-price-card">
                            <div className="home-page-price-card-info">
                                <div className="home-page-price-card-type">
                                    Basic
                                </div>
                                <div className="home-page-price-card-cost">
                                    Free
                                </div>
                                <div className="home-page-price-card-short-desc">
                                    All the basic features to start organising your life.
                                </div>
                            </div>
                            <div className="home-page-price-card-features">
                                <div className="home-page-price-card-feature">
                                    <img src={pricing_feature} alt="Feature Icon" />
                                    <div className="home-page-price-card-feature-text">
                                        Store up to 3 items
                                    </div>
                                </div>
                                <div className="home-page-price-card-feature">
                                    <img src={pricing_feature} alt="Feature Icon"/>
                                    <div className="home-page-price-card-feature-text">
                                        View your data online
                                    </div>
                                </div>
                                <div className="home-page-price-card-feature">
                                    <img src={pricing_feature} alt="Feature Icon"/>
                                    <div className="home-page-price-card-feature-text">
                                        Add unlimited nominees
                                    </div>
                                </div>
                                <div className="home-page-price-card-feature">
                                    <img src={pricing_feature} alt="Feature Icon"/>
                                    <div className="home-page-price-card-feature-text">
                                        Dedicated secure vault for your document
                                    </div>
                                </div>
                                <div className="home-page-price-card-feature">
                                    <img src={pricing_feature} alt="Feature Icon"/>
                                    <div className="home-page-price-card-feature-text">
                                        Helpful reminders to keep everything up to date
                                    </div>
                                </div>
                                <div className="home-page-price-card-feature">
                                    <img src={pricing_feature} alt="Feature Icon"/>
                                    <div className="home-page-price-card-feature-text">
                                        Latest security and encryption to protect your data
                                    </div>
                                </div>
                                <div className="home-page-price-card-feature">
                                    <img src={pricing_feature} alt="Feature Icon"/>
                                    <div className="home-page-price-card-feature-text">
                                        Monthly newsletter
                                    </div>
                                </div>
                                <div className="home-page-price-card-button-container">
                                    <Link to={"/register"}>
                                        <button className="home-page-price-card-button"> Get started </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="home-page-price-card-container col-sm-6">
                        <div className="home-page-price-card">
                            <div className="home-page-price-card-info">
                                <div className="home-page-price-card-type">
                                    Plus
                                </div>
                                <div className="home-page-price-card-cost">
                                    £2
                                    <div className="home-page-price-card-period">
                                        / month  (or £20 per annum)
                                    </div>
                                </div>
                                <div className="home-page-price-card-short-desc">
                                    All the features to give you peace of mind
                                </div>
                            </div>
                            <div className="home-page-price-card-features">
                                <div className="home-page-price-card-feature">
                                    <img src={pricing_feature} alt="Feature Icon" />
                                    <div className="home-page-price-card-feature-text">
                                        <b> Everything from Basic plan, plus: </b>
                                    </div>
                                </div>
                                <div className="home-page-price-card-feature">
                                    <img src={pricing_feature} alt="Feature Icon"/>
                                    <div className="home-page-price-card-feature-text">
                                        Store unlimited items
                                    </div>
                                </div>
                                <div className="home-page-price-card-feature">
                                    <img src={pricing_feature} alt="Feature Icon"/>
                                    <div className="home-page-price-card-feature-text">
                                        Link your bank accounts to track all regular payments
                                    </div>
                                </div>
                                <div className="home-page-price-card-feature">
                                    <img src={pricing_feature} alt="Feature Icon"/>
                                    <div className="home-page-price-card-feature-text">
                                        Snapshot report of your life’s details
                                    </div>
                                </div>
                                <div className="home-page-price-card-feature">
                                    <img src={pricing_feature} alt="Feature Icon"/>
                                    <div className="home-page-price-card-feature-text">
                                        Financial health reports
                                    </div>
                                </div>
                                <div className="home-page-price-card-feature">
                                    <img src={pricing_feature} alt="Feature Icon"/>
                                    <div className="home-page-price-card-feature-text">
                                        Identity check for nominees on death
                                    </div>
                                </div>
                                <div className="home-page-price-card-button-container">
                                    <Link to={"/register"}>
                                        <button className="home-page-price-card-button plus"> Get 14 Days Free Trial </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomepagePricing