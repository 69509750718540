import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import NavBar from "../../components/NavBar";
import HomeFooter from "../../components/HomeFooter";
import BlogArticleSubpage from "../../components/subpages/BlogArticleSubpage";

const BlogArticle = () => {
    let params = useParams();

    const [link, setLink] = useState()

    useEffect(() => {
        setLink(params.link)
    }, [params.link])


    return (
        <>
            <div className="home-page-container" id="blog-article-home-container">
                <NavBar/>
                <BlogArticleSubpage link={link} />
                <HomeFooter />
            </div>
        </>
    )
}

export default BlogArticle