import {useEffect, useState} from "react";
import currencyUtils from "../utils/currencyUtils"
import dateUtils from "../utils/dateUtils"

import close from "../assets/close.webp"

import { getMerchantLogoUrl, getMerchantName, getTransactionAmount, getTransactionLastDate, parseAmount } from "./TransactionsTable";

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getTransactionFrequency(transaction) {
    if('periodicity' in transaction) {
        return capitalize(transaction.periodicity);
    }
    if('enrichments' in transaction && 'regularity' in transaction.enrichments) {
        return capitalize(transaction.enrichments.regularity.frequency);
    }
    return "Single Transaction";
}

const TransactionDetailsDialogue = (props) => {

    const closeDisplay = props.closeDisplay

    const [display, setDisplay] = useState()
    const [transaction, setTransaction] = useState()

    useEffect(() => {
        if (!props.transaction) return;
        setTransaction(props.transaction)
    }, [props.transaction])

    useEffect(() => {
        setDisplay(props.display)
    }, [props.display])

    return (
        <>
            {display ?
                <>
                    <div className="page-mask"/>
                    <div className="transaction-details-dialogue">
                        <div className="transaction-details-dialogue-top-container">
                            <div className="transaction-details-dialogue-heading-container">
                                <div className="transaction-details-dialogue-heading">
                                    {getMerchantName(transaction)}
                                </div>
                            </div>
                            <button id="close" onClick={closeDisplay} className="transaction-details-dialogue-close">
                                <img src={close} id="close"/>
                            </button>
                        </div>

                        <div className="transaction-details-dialogue-row-container">
                            <div className="transaction-details-dialogue-row-label">
                                Bank
                            </div>
                            <div className="transaction-details-dialogue-row-value">
                                {transaction.provider}
                            </div>
                        </div>

                        <div className="transaction-details-dialogue-row-container">
                            <div className="transaction-details-dialogue-row-label">
                                Amount
                            </div>
                            <div className="transaction-details-dialogue-row-value">
                                {getTransactionAmount(transaction)}
                            </div>
                        </div>

                        <div className="transaction-details-dialogue-row-container">
                            <div className="transaction-details-dialogue-row-label">
                                Frequency
                            </div>
                            <div className="transaction-details-dialogue-row-value">
                                {getTransactionFrequency(transaction)}
                            </div>
                        </div>

                        <div className="transaction-details-dialogue-row-container">
                            <div className="transaction-details-dialogue-row-label">
                                Last transaction
                            </div>
                            <div className="transaction-details-dialogue-row-value">
                                {getTransactionLastDate(transaction)}
                            </div>
                        </div>

                    </div>
                </>

                :
                null
            }
        </>
    )
}

export default TransactionDetailsDialogue