import logo from "../assets/logo.webp"

import facebook from "../assets/socials/facebook.webp"
import linkedin from "../assets/socials/linkedin.webp"
import tiktok from "../assets/socials/tiktok.webp"
import {Link, useNavigate} from "react-router-dom";

const HomeFooter = () => {
    const navigate = useNavigate();

    const HomeNavigate = (section) => {
        if (!section) return;
        navigate("/home?page=" + section)
    }

    const LinkTo = (section) => {
        if (!section) return;
        navigate("/" + section)
    }

    return (
        <div className="home-page-footer-container">
            <div className="home-page-footer-info-container">
                <div className="row">
                    <div className="footer-column-container col-sm-4">
                        <div className="footer-column">
                            <div className="footer-logo-container">
                                <img src={logo} alt="snapshot logo"/>
                            </div>
                            <div className="footer-slogan-container">
                                Organise your life in 3 minutes
                            </div>
                            <div className="footer-socials-container">
                                <Link to={"https://www.tiktok.com/@snapshot.life"}>
                                    <img src={tiktok} alt="TikTok Link" />
                                </Link>
                                <Link to={"https://www.linkedin.com/company/headstone-life"}>
                                    <img src={linkedin}  alt="LinkedIn Link" />
                                </Link>
                                <Link to={"https://www.facebook.com/Snapshotsupport"}>
                                    <img src={facebook}  alt="Facebook Link" />
                                </Link>
                            </div>
                        </div>

                    </div>
                    <div className="footer-column-container col-sm-4">
                        <div className="footer-column">
                            <div className="footer-link-title">
                                Company
                            </div>
                            <div tabIndex="0" className="footer-link link" onClick={(event) => {HomeNavigate("features")}}>
                                Features
                            </div>
                            <div tabIndex="0" className="footer-link link" onClick={(event ) => {HomeNavigate("pricing")}}>
                                Pricing
                            </div>
                            <div tabIndex="0" className="footer-link link" onClick={(event) => {HomeNavigate("our-story")}}>
                                Our story
                            </div>
                            <div tabIndex="0" className="footer-link link" onClick={(event) => {HomeNavigate("partnerships")}}>
                                Partnerships
                            </div>
                            <div tabIndex="0" className="footer-link link" onClick={(event) => {HomeNavigate("faqs")}}>
                                FAQs
                            </div>
                            <div tabIndex="0" className="footer-link link blog" onClick={(event) => {LinkTo("blog")}}>
                                Blog
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="home-page-footer-copyright-container">
                © Copyright 2023, All Rights Reserved Snapshot
            </div>
        </div>
    )
}

export default HomeFooter