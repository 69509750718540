import RegisterPageProgress from "../RegisterPageProgress";
import {useState} from "react";
import contacts_api from "../../api/contacts";

const RegisterNextOfKin = (props) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");

    const [error, setError] = useState("")

    const nextSubpage = props.next
    const setSubpage = props.setPage
    const UpdateNextOfKin = props.updateNextOfKin

    const handleAddNextOfKin = (event) => {
        event.preventDefault();
        SplitName()
    }

    const SplitName = () => {
        let names = name.split(" ")
        if (names.length < 2 || names[1] === "") {
            setError("Please include a First and a Last name")
            return
        }
        let firstName;
        let lastName = "";
        names.map((name, idx) => {
            if (idx === 0) firstName = name
            else lastName += name + " "
        })
        lastName = lastName.trim()
        addNextOfKin(firstName, lastName);
    }

    const addNextOfKin = (first, last) => {
        let formData = {
            "email": email,
            "first_name": first,
            "last_name": last,
            "relationship": "Next Of Kin",
            "phone": mobileNumber
        }
        let response = contacts_api.postContact(formData);
        response.then(
            response => {
                if(response.data.error) {
                    setError(response.data.error)
                }
                else {
                    UpdateNextOfKin(email)
                    nextSubpage()
                }
            }
        ).catch((err) => {
            console.log(err)
        })

    }

    return (
        <div className="register-subpage-container" style={!props.show ? {display: "none"} : {}}>
            <RegisterPageProgress page={props.page ? props.page : 0} />
            <div className="register-page-subpage-container" >
                <form name="register-form" onSubmit={handleAddNextOfKin}>
                    <div className="register-page-title">
                    Account setup
                </div>
                    <div className="register-page-subtitle two-factor">
                        Next of Kin
                    </div>
                    <div className="register-page-description two-factor">
                        Secure your legacy and share your important documents with those who matter. Add your next of kin
                        or trusted individual.
                    </div>
                    <div className="register-page-description two-factor">
                        Don't worry, they won't have access to any of your reports or documents until you give them
                        permission to do so.
                    </div>

                    <div className="register-page-inputs-container">
                        <div className="register-form-element-container">
                            <div className="register-form-element-label">Name*</div>
                            <input type="text" required
                                   onChange={(ev) => {setName(ev.target.value)}}/>
                        </div>

                        <div className="register-form-element-container">
                            <div className="register-form-element-label">Email*</div>
                            <input type="email" required
                                   onChange={(ev) => {setEmail(ev.target.value)}}/>
                        </div>

                        <div className="register-form-element-container">
                            <div className="register-form-element-label">Mobile number*</div>
                            <input type="tel" required
                                   onChange={(ev) => {setMobileNumber(ev.target.value)}}/>
                        </div>

                        <div className="register-error-message">
                            {error && error}
                        </div>
                    </div>

                    <div className="two-factor-button-container">
                        <input type="submit" value="Next" className="verify-submit-button link"/>
                        <div tabIndex="0" className="two-factor-no-text link" onClick={(ev) => setSubpage(props.page + 2)}>
                            Skip for now
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RegisterNextOfKin