import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import RegisterPageProgress from "../RegisterPageProgress";

import users_api from "../../api/users";
import auth from "../../api/auth";

const RegisterEmailVerification = (props) => {
    const navigate = useNavigate()

    const [error, setError] = useState("")
    const [message, setMessage] = useState("")

    const [verificationLetter1, setVerificationLetter1] = useState("")
    const [verificationLetter2, setVerificationLetter2] = useState("")
    const [verificationLetter3, setVerificationLetter3] = useState("")
    const [verificationLetter4, setVerificationLetter4] = useState("")
    const [verificationLetter5, setVerificationLetter5] = useState("")
    const [verificationLetter6, setVerificationLetter6] = useState("")

    const nextSubpage = props.next
    const setPage = props.setPage

    const [email, setEmail] = useState()

    useEffect(() => {
        if (!props.email) return;
        setEmail(props.email)
    }, [props.email])

    useEffect(() => {
        if (props.show && email) {
            setMessage("");
            setError("");
            setVerificationLetter1("");
            setVerificationLetter2("");
            setVerificationLetter3("");
            setVerificationLetter4("");
            setVerificationLetter5("");
            setVerificationLetter6("");
            SendUserEmail(false);
        }
    }, [props.show, email])

    const handleVerify = (event) => {
        event.preventDefault();
        setError("")
    }

    const CheckVerificationData = () => {
        let string = (verificationLetter1 + verificationLetter2 + verificationLetter3 + verificationLetter4 + verificationLetter5 + verificationLetter6)
        let lengthCheck = string.length === 6
        return [lengthCheck].every(Boolean);
    }

    const handleVerifySubmit = (event) => {
        if (!CheckVerificationData) setError("The data you have inputted is invalid")
        let verificationCode = (verificationLetter1 + verificationLetter2 + verificationLetter3 + verificationLetter4 + verificationLetter5 + verificationLetter6)
        VerifyUserEmail(verificationCode);
    }

    const VerifyUserEmail = (verificationCode) => {
        if (!verificationCode) return;
        let formData = {
            "apiKey": process.env.REACT_APP_SERVER_API_KEY,
            "code": verificationCode,
            "register": true
        }
        let response = users_api.verifyEmail(formData);
        response.then(
            response => {
                if(response.data.error) {
                    setError(response.data.error)
                }
                else nextSubpage()
            }
        ).catch((err) => {
            setError("Verification request failed")
            console.log(err)
        })
    }

    const SendUserEmail = (resend) => {
        if (!email) return;
        let formData = {
            "apiKey": process.env.REACT_APP_SERVER_API_KEY,
            "email": email
        }
        let response = users_api.resendEmail(formData);
        response.then(
            response => {
                if (response.data && response.data.error){
                    if (resend)
                        setError("Verification Code failed to resend")
                    else
                        setError("Verification Code failed to send")
                }
                else if (resend)
                    setMessage("Verification Code has been resent")
            }
        ).catch((err) => {
            if (resend)
                setError("Verification Code failed to resend")
            else
                setError("Verification Code failed to send")
        })
    }


    return (
        <div className="register-subpage-container" style={!props.show ? {display: "none"} : {}}>
            <RegisterPageProgress page={props.page ? props.page : 0} />
            <div className="register-page-verification-container" >
                <form name="register-form" className="register-form" onSubmit={handleVerify}>
                    <div className="register-page-title">
                        Account setup
                    </div>
                    <div className="register-page-subtitle verification">
                        Email verification
                    </div>
                    <div className="register-page-description verification">
                        We’ve sent a 6 character code to your email address. Please verify your account.
                    </div>
                    <div className="verify-form-container">
                        <input type='text' id='verify-email-1' className='verify-form-character' value={verificationLetter1}
                               maxLength='1' onChange={(ev) => {
                                setVerificationLetter1(ev.target.value)
                            if (ev.target.value !== "") document.getElementById('verify-email-2').focus();}}/>
                        <input type='text' id='verify-email-2' className='verify-form-character' value={verificationLetter2}
                               maxLength='1' onChange={(ev) => {
                                setVerificationLetter2(ev.target.value)
                            if (ev.target.value !== "") document.getElementById('verify-email-3').focus();}}/>
                        <input type='text' id='verify-email-3' className='verify-form-character' value={verificationLetter3}
                               maxLength='1' onChange={(ev) => {
                                setVerificationLetter3(ev.target.value)
                            if (ev.target.value !== "") document.getElementById('verify-email-4').focus();}}/>
                        <input type='text' id='verify-email-4' className='verify-form-character' value={verificationLetter4}
                               maxLength='1' onChange={(ev) => {
                                setVerificationLetter4(ev.target.value)
                            if (ev.target.value !== "") document.getElementById('verify-email-5').focus();}}/>
                        <input type='text' id='verify-email-5' className='verify-form-character' value={verificationLetter5}
                               maxLength='1' onChange={(ev) => {
                               setVerificationLetter5(ev.target.value)
                            if (ev.target.value !== "") document.getElementById('verify-email-6').focus();}}/>
                        <input type='text' id='verify-email-6' className='verify-form-character' value={verificationLetter6}
                               maxLength='1' onChange={(ev) => {
                                setVerificationLetter6(ev.target.value)}}/>
                    </div>

                    <div className="register-error-message">
                        {error && error}
                    </div>
                    <div className="register-message">
                        {message && message}
                    </div>

                    <div className="verify-submit-button-container">
                        <input type="submit" value="Verify" className="verify-submit-button link"
                               disabled={!CheckVerificationData()} onClick={handleVerifySubmit}/>
                    </div>

                    <div className="verify-resend-code link" onClick={(ev) => SendUserEmail(true)}>
                        Resend verification code
                    </div>
                    <div className="return-to-details link" onClick={(ev) => setPage(0)}>
                        Return to details
                    </div>

                </form>
            </div>
        </div>
    )
}

export default RegisterEmailVerification