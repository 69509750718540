import banner01 from "../../assets/dynamicBanner/Banner1.webp";
import banner02 from "../../assets/dynamicBanner/Banner2.webp";
import banner03 from "../../assets/dynamicBanner/Banner3.webp";
import banner04 from "../../assets/dynamicBanner/Banner4.webp";
import banner05 from "../../assets/dynamicBanner/Banner5.webp";
import banner06 from "../../assets/dynamicBanner/Banner6.webp";

import {useEffect, useState} from "react";

const HomepageDynamicBanner = () => {

    const images = [
        banner01, banner02, banner03, banner04, banner05, banner06
    ]

    const [text, setText] = useState(
        [
            "peace of mind",
            "to feel organised",
            "an easy-to-use platform",
            "financial clarity",
            "all of life's important details in one place",
            "peace of mind",
            "to feel organised",
            "an easy-to-use platform",
            "financial clarity",
            "all of life's important details in one place",
        ]);

    const [currentText, setCurrentText] = useState(0)
    const [currentImage, setCurrentImage] = useState(0)

    const seconds_between_rotation = 2

    useEffect(() => {
        const interval = setInterval(() => {
            updateVisibleStory()
        }, seconds_between_rotation * 1000);
        return () => clearInterval(interval);

    }, [text, currentText]);

    const updateVisibleStory = () => {
        if (currentText === 9) setCurrentText(0)
        else setCurrentText(currentText + 1)

        if (currentImage === 5) setCurrentImage( 0)
        else setCurrentImage(currentImage + 1)
    }

    return (
        <>
            <div tabIndex="0" className="homepage-dynamic-banner-container">
                {images && images.map((item, idx) => {
                    return (
                        <img src={item} alt="banner" className="homepage-dynamic-banner-image"
                        style={ currentImage === idx ? {opacity: "100%"} : {opacity: "0"}}/>
                    )
                })}
                <div className="home-page-dynamic-banner-opacity-layer" />
                <div className="homepage-dynamic-banner-text-static"> I need </div>
                <div className="homepage-dynamic-banner-text-dynamic-container">
                    <div className="homepage-dynamic-banner-text-dynamic-element-container">
                        {text && text.map((item, idx) => {
                            return (
                                <div className="homepage-dynamic-banner-item" style={
                                    {
                                        top: (idx - currentText) < -2
                                            ?
                                            (idx - currentText) * 35 + (text.length * 35) + "px"
                                            :
                                            (idx - currentText) * 35 + "px",
                                        display: ((idx - currentText) * 35 >= -35 && (idx - currentText) * 35 <= 175)
                                        || ((idx - currentText) * 35 + (text.length * 35) >= -35 && (idx - currentText) * 35 + (text.length * 35) <= 175) ?
                                            "block" : "none",
                                        filter: idx - currentText === 2 || idx - currentText === -8 ? "none" : "blur(2px)"
                                    }
                                }>
                                    {item}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomepageDynamicBanner