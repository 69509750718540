import http_api from "./http/http_api";

const budIsAuthenticated = () => http_api.get("/44968d3ddb237d5c8c9320019/checkBud");

const budAuthenticate = () => http_api.post("/auth/thisbud");
const budRefresh = () => http_api.post("/thisbud/e38xnqyfizw09nmvz/refresh");

const revokeProvider = (provider) => http_api.post("/thisbud/e38xnqyfizw09nmvz/revoke/provider", {
    "provider": provider
})

export default {
    budAuthenticate,
    budRefresh,
    budIsAuthenticated,
    revokeProvider
}