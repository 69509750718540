import {useEffect, useState} from "react";
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import download from "../assets/reports/download.webp";
import {isMobile} from "react-device-detect";

const FinancialReport = (props) => {

    const [report, setReport] = useState()

    useEffect(() => {
        if (!props.report) return;
        setReport(props.report)
    }, [props.report])

    return (
        <div style={props.show ? {display: "block"} : {display: "none"}}
             className="reports-subpage-preview-container financial">
            {isMobile ? null : <PDFViewer className="report-viewer">{report && report}</PDFViewer> }
            <div className="report-transparent-cover">
                <div className="report-description">
                    Your financial report is private to you. You’ll see all of your financial transactions from the
                    bank accounts you have linked
                </div>
                <div className="report-description">
                    We recommend you review this monthly to help keep on top of your finances.
                </div>
                {report && report ?
                    <PDFDownloadLink document={report} fileName="financial_report.pdf"
                                     className="report-download-button link">
                        <img src={download} alt="Download"/>
                        Download your Financial report
                    </PDFDownloadLink>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default FinancialReport