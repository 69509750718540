
const HomepageDidYouKnow = () => {
    return (
        <div className="homepage-did-you-know-container">
            <div className="homepage-did-you-know-title">
                Did you know?
            </div>
            <div className="homepage-did-you-know-cards-container">
                <div className="homepage-did-you-know-background"></div>
                <div className="row">
                    <div className="homepage-did-you-know-card-container">
                        <div className="homepage-did-you-know-card">
                            <div className="homepage-did-you-know-card-title">
                                2.5bn
                            </div>
                            <div className="homepage-did-you-know-card-description">
                                The average human life is 2.5 billion seconds long. It only takes 180 seconds to
                                get your key details uploaded and reports generated with Snapshot.
                            </div>
                        </div>
                    </div>
                    <div className="homepage-did-you-know-card-container">
                        <div className="homepage-did-you-know-card">
                            <div className="homepage-did-you-know-card-title">
                                8,000
                            </div>
                            <div className="homepage-did-you-know-card-description">
                                There are 8,000+ cases in the UK of unclaimed inheritance awaiting an heir to be found.
                            </div>
                        </div>
                    </div>
                    <div className="homepage-did-you-know-card-container">
                        <div className="homepage-did-you-know-card">
                            <div className="homepage-did-you-know-card-title">
                                82%
                            </div>
                            <div className="homepage-did-you-know-card-description">
                                82% of people find it difficult to relax. Being disorganised makes it more difficult to
                                relax, both physically and mentally.
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default HomepageDidYouKnow