import sharing_api from "../api/sharing"

const reportMiddleman = (user_id) => {

    let get_data = new Promise(function (resolve) {
        let contacts_response = sharing_api.getOthersContacts(user_id);
        contacts_response.then(
            contacts_response => {
                let contacts = contacts_response.data.contacts;
                let documents_response = sharing_api.getOthersDocuments(user_id);
                documents_response.then(
                    documents_response => {
                        let documents = documents_response.data.documents;
                        let transactions_response = sharing_api.getOthersRegularTransactions(user_id);
                        transactions_response.then(
                            transactions_response => {
                                let transactions = transactions_response.data.transactions;
                                let accounts_response = sharing_api.getOthersAccounts(user_id);
                                accounts_response.then(
                                    accounts_response => {
                                        let accounts = accounts_response.data.accounts;
                                        let user_response = sharing_api.getOtherUser(user_id);
                                        user_response.then(
                                            user_response => {
                                                let user = user_response.data.profile;
                                                let data = {
                                                    "transactions": transactions,
                                                    "contacts": contacts,
                                                    "user": user,
                                                    "accounts": accounts,
                                                    "documents": documents
                                                }
                                                resolve(data);
                                            }
                                        )
                                    }
                                )

                            }
                        )

                    }
                )
            }
        )
    });

    get_data.then((data) => {
        return get_data
    })

    return get_data
}

export default reportMiddleman;