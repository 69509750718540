import logo from "../assets/logo.webp"
import {useNavigate} from "react-router-dom";


const NavBar = () => {
    const navigate = useNavigate()

    const HomeNavigate = (section) => {
        if (!section) return;
        navigate("/home?page=" + section)
    }

    return (
        <div className="nav-bar-container">
            <div className="nav-bar-content">
                <div className="nav-bar-logo">
                <div tabIndex="0" className="nav-bar-image-container link"
                     onClick={(event) => {HomeNavigate("landing")}}>
                    <img src={logo} alt="Snapshot Logo"/>
                </div>
            </div>
                <div className="nav-bar-links-container">



                <div tabIndex="0" className="nav-bar-link link" onClick={(event) => {HomeNavigate("features")}}>
                    Features
                </div>
                <div tabIndex="0" className="nav-bar-link link" onClick={(event) => {HomeNavigate("pricing")}}>
                    Pricing
                </div>
                <div tabIndex="0" className="nav-bar-link link" onClick={(event) => {HomeNavigate("our-story")}}>
                    Our Story
                </div>
                <div tabIndex="0" className="nav-bar-link link" onClick={(event) => {HomeNavigate("partnerships")}}>
                    Partnerships
                </div>
                <div tabIndex="0" className="nav-bar-link link" onClick={(event) => {HomeNavigate("faqs")}}>
                    FAQs
                </div>
            </div>
                <div className="nav-bar-login-buttons-container">
                <div tabIndex="0" onClick={(ev) => {navigate("/register")}} className="nav-bar-login link">
                    Sign up
                </div>
                <div tabIndex="0" onClick={(ev) => {navigate("/login")}} className="nav-bar-register link">
                    Log in
                </div>
            </div>
            </div>
        </div>
    )
}

export default NavBar