import RegisterPageProgress from "../RegisterPageProgress";
import users_api from "../../api/users";
import {useState} from "react";

const Register2Factor = (props) => {
    const [error, setError] = useState("")

    const nextSubpage = props.next
    const email = props.email;
    const code = props.code;

    const handleEnable = (ev) => {
        if (!email) return;
        let formData = {
            "apiKey": process.env.REACT_APP_SERVER_API_KEY,
            "email": email,
            "code": code
        }
        let response = users_api.enable2FA(formData);
        response.then(
            response => {
                if(response.data.error) {
                    setError(response.data.error)
                }
                else nextSubpage()
            }
        ).catch((err) => {
            setError("Verification request failed")
            console.log(err)
        })
    }

    return (
        <div className="register-subpage-container" style={!props.show ? {display: "none"} : {}}>
            <RegisterPageProgress page={props.page ? props.page : 0} />
            <div className="register-page-verification-container" >
                <div className="register-page-title">
                    Account setup
                </div>
                <div className="register-page-subtitle two-factor">
                    Enable 2-step verification
                </div>
                <div className="register-page-description two-factor">
                    Protect your account with an extra layer of security. Set up 2-step verification to ensure only you
                    can access your information
                </div>

                <div className="register-error-message">
                    {error && error}
                </div>

                <div className="two-factor-button-container">
                    <input type="submit" value="Enable" className="verify-submit-button link"
                           onClick={handleEnable}/>
                    <div tabIndex="0" className="two-factor-no-text link" onClick={(ev) => nextSubpage()}>
                        No Thanks
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register2Factor