import {useEffect, useState} from "react";

import SideNav from "../../components/SideNav";

import sharing_api from "../../api/sharing";

import UserReport from "../../components/UserReport";

const SharedWithYou = () => {

    const [reportsMenu, setReportsMenu] = useState();
    const [showSection, setShowSection] = useState(0);

    const [sharers, setSharers] = useState()

    useEffect(() => {
        getSharedWithMe()
    }, [])


    const getSharedWithMe = () => {
        let response = sharing_api.getSharedWithMe();
        response.then(
            response => {
                let data = response.data.shares
                let menus = [];
                let menu = {};

                data.forEach((sharer) => {
                    menu = {};
                    menu.name= sharer.first_name + "'s Snapshot";
                    menu.activeClass = "link report-menu-item";
                    menus.push(menu);
                })

                if (menus.length > 0) {
                    menus[0].activeClass += " active"
                }

                setSharers(data)
                setReportsMenu(menus);
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    const clickMenu = (ev) => {
        if (ev.target.id === "") return;
        updateMenu(ev.target.textContent);
    }

    const updateMenu = (selection) => {
        if (!reportsMenu) return;
        let updateMenu = [...reportsMenu];
        let idx = updateMenu.map(item => item.name).indexOf(selection);
        for(let i=0;i<updateMenu.length;i++){
            if (i !== idx){
                updateMenu[i].activeClass = "link report-menu-item";
            }
            else {
                updateMenu[i].activeClass = "active link report-menu-item";
                setShowSection(i);
            }
        }
        setReportsMenu(updateMenu);
    }

    return (
        <div className="page-container">
            <SideNav />
            <div className="reports-subpage-container">
                <div className="reports-subpage-navigation-container">
                    <div className="reports-subpage-navigation-title">
                        Shared with you
                    </div>
                    <div className="reports-menu">
                        {reportsMenu && reportsMenu.length > 0 ?
                            reportsMenu.map((menu, idx) => {
                                return (
                                    <div className={menu.activeClass} id={menu.name} onClick={clickMenu}>{menu.name}</div>
                                )
                            })
                            :
                            "There are currently no reports shared with you."
                        }
                    </div>
                </div>
                {sharers && sharers[showSection] ?
                    <UserReport user={sharers[showSection]}/>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default SharedWithYou;