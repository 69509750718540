import RegisterPageProgress from "../RegisterPageProgress";
import {useState} from "react";

import tick from "../../assets/incomplete.webp";
import stripe_api from "../../api/stripe";


const RegisterPaywall = (props) => {

    const nextSubpage = props.next
    const [yearly, setYearly] = useState(false)

    const handleFree = (event) => {
        nextSubpage()
    }

    const CreateCheckOutSession = (type) => {
        let response = stripe_api.createCheckoutSession(type, "register?page=complete", "register?page=subscription");
        response.then(
            response => {
                let url = response.data.url
                window.location.replace(url)
            }
        ).catch((err) => {
            console.log(err)
        })
    }


    return (
        <div className="register-subpage-container" style={!props.show ? {display: "none"} : {}}>
            <RegisterPageProgress page={props.page ? props.page : 0} />
            <div className="register-page-subpage-container" >
                <div className="register-page-title">
                    Account setup
                </div>

                <div className="register-page-advanced-container">
                    <div className="register-page-subtitle two-factor">
                        Access Plus features
                    </div>
                    <div className="register-page-description two-factor">
                        Get the most out of Snapshot
                    </div>


                    <div className="register-plus-features-container">
                        <div className="register-plus-feature">
                            <img src={tick} alt={"tick"}/>
                            <div className="register-plus-feature-text">
                                Store unlimited items
                            </div>
                        </div>

                        <div className="register-plus-feature">
                            <img src={tick} alt={"tick"}/>
                            <div className="register-plus-feature-text">
                                Link your bank accounts to track all regular payments
                            </div>
                        </div>

                        <div className="register-plus-feature">
                            <img src={tick} alt={"tick"}/>
                            <div className="register-plus-feature-text">
                                Snapshot report of your life’s details
                            </div>
                        </div>

                        <div className="register-plus-feature">
                            <img src={tick} alt={"tick"}/>
                            <div className="register-plus-feature-text">
                                Financial health reports
                            </div>
                        </div>

                        <div className="register-plus-feature">
                            <img src={tick} alt={"tick"}/>
                            <div className="register-plus-feature-text">
                                Identity check for nominees on death
                            </div>
                        </div>
                    </div>

                    <div className="register-plus-pricing-container">
                        <div className="register-plus-monthly-container">
                            <div className={!yearly ? "register-period-radio selected" : "register-period-radio link"}
                                 onClick={(ev) => setYearly(false)} />
                            <div className="register-plus-type">
                                Monthly
                            </div>
                            <div className="register-plus-price-container">
                                <div className="register-plus-price">
                                    £2
                                </div>
                                <div className="register-plus-period">
                                    /month
                                </div>
                            </div>
                        </div>
                        <div className="register-plus-yearly-container">
                            <div className={yearly ? "register-period-radio selected" : "register-period-radio link"}
                                 onClick={(ev) => setYearly(true)} />
                            <div className="register-plus-type">
                                Yearly
                            </div>
                            <div className="register-plus-price-container">
                                <div className="register-plus-price">
                                    £20
                                </div>
                                <div className="register-plus-period">
                                    /year
                                </div>
                            </div>

                        </div>

                        <button className="plus-subscribe-button link" onClick={(ev) => {
                            if (yearly) CreateCheckOutSession("plus-yearly")
                            else CreateCheckOutSession("plus")
                        }}>
                            Subscribe
                        </button>


                    </div>
                </div>


                <div className="free-plan-button-container">
                    <button className="free-plan-button link" onClick={handleFree}>
                        Continue with Free plan
                    </button>
                </div>
            </div>
        </div>
    )
}

export default RegisterPaywall;