import {useEffect, useState} from "react";
import contacts_api from "../../api/contacts";
import SideNav from "../../components/SideNav";

import arrow from "../../assets/arrow.webp"
import AddContact from "../../components/AddContact";

const Contacts = () => {

    const [onContact, setOnContact] = useState(false)
    const [contacts, setContacts] = useState()

    const [inspectingContact, setInspectingContact] = useState()

    useEffect(() => {
        GetContacts()
    }, [])

    const GetContacts = () => {
        let response = contacts_api.getExecutors();
        response.then(
            response => {
                setContacts(response.data.executors)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    const handleInspectContact = (ev) => {
        if (!contacts) return;
        if (!ev.target.id && ev.target.id !== 0) return
        setInspectingContact(contacts[ev.target.id])
        setOnContact(true)
    }

    const hideContact = (ev) => {
        setOnContact(false)
        setInspectingContact(undefined)
    }

    return (
        <div className="page-container">
            <SideNav />
            <div className="executors-subpage-container">
                {onContact && onContact ?
                    <div className="individual-contact-page-container">
                        <div className="individual-contact-page-title-container">
                            <img className="contact-page-back-button link" src={arrow} id="hide" onClick={hideContact}/>
                            <div className="contact-page-title">Executors</div>
                        </div>
                        <div className="add-contact-component-container">
                            <AddContact contact={inspectingContact} refreshContacts={GetContacts.bind(this)}
                                        hideContact={hideContact.bind(this)}/>
                        </div>
                    </div>
                    :
                    <div className="your-executors-container">
                        <div className="executors-title-container">
                            <div className="executors-title">
                                Your Executors
                            </div>
                        </div>
                        <div className="executors-table-container">
                            <div className="table-headers-container tr">
                                <div className="table-header-cell-container full-name th">
                                    <div className="table-header-word"> Name </div>
                                </div>
                                <div className="table-header-cell-container permissions th">
                                    <div className="table-header-word"> Permissions </div>
                                </div>
                            </div>
                            <div className="table-rows-container">
                                {contacts && contacts.map((contact, idx) => {
                                    return(
                                        <div className="table-row-container">
                                            <div className="table-body-cell full-name">
                                                <div className="table-body-cell-full-name">
                                                    {contact.first_name + " " + contact.last_name}
                                                </div>
                                            </div>

                                            <div className="table-body-cell permissions">
                                                <div className="table-body-cell-permissions">
                                                    {contact.hasShareRights ? "Access now" :
                                                        (contact.hasShareLaterRights ? "Access later" : "No access")}
                                                </div>
                                            </div>

                                            <div className="table-body-cell edit link" onClick={handleInspectContact} id={idx}>
                                                <div className="table-body-cell-edit" id={idx}>
                                                    Edit
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Contacts