import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {sanitize} from "dompurify";

import knowledge_api from "../../api/knowledge"

import arrow from "../../assets/arrow.webp"
import next_arrow from "../../assets/blog-card-arrow-light.webp"


const GuideGuideSubpage = (props) => {
    let navigate = useNavigate()

    const [guide, setGuide] = useState()

    const [relatedGuides, setRelatedGuides] = useState()

    useEffect(() => {
        if (!props.link) return;
        getGuide()
    }, [props.link])

    const getGuide = () => {
        let response = knowledge_api.getGuide(props.link);
        response.then(
            response => {
                let data = response.data.articles[0]
                setGuide(data)
                getRelatedGuides(data.category)
            }
        ).catch((err) => {
            console.log(err)
        })

    }

    const getRelatedGuides = (category) => {
        let response = knowledge_api.getRelatedGuides(category);
        response.then(
            response => {
                let data = response.data.articles;
                setRelatedGuides(data)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    const NavigateTo = (link) => {
        navigate(link)
    }

    return (
        <>
            <div className="guide-article-container">
                <div className="guide-article">
                    <div className="back-arrow-container link">
                        <img src={arrow} alt={"Back arrow"} style={{rotate: "180deg"}}
                             onClick={(ev) => {NavigateTo("/knowledge-hub")}} />
                    </div>
                    <div className="guide-article-category">
                        {guide && guide.category}
                    </div>
                    <div className="guide-article-title-container">
                        <h1 className="guide-article-title">
                            {guide && guide.title}
                        </h1>
                    </div>

                    <div className="guide-article-image-container">
                        <img src={guide && guide.image} alt={"Hero Image"} />
                    </div>
                    <div className="guide-article-body-container">
                        {guide && guide.bodies && guide.bodies.map((section, idx) => {
                            return (
                                <>
                                    <div className="guide-article-body"
                                         dangerouslySetInnerHTML={{__html: sanitize(section.content, {ADD_ATTR: ["target"]})}} />
                                </>
                            )
                        })}
                    </div>
                </div>
                <div className="guide-article-separator" />
                <div className="guide-article-cards-container">
                    <div className="more-guide-text">
                        More Guides
                    </div>
                    <div className="row">
                        {relatedGuides && relatedGuides.map((guide, idx) => {
                            return (
                                <div className="col-sm-4">
                                    <Link to={"/guide/" + guide.link} className="link">
                                        <div className="guide-article-card-container">
                                            <div className="guide-article-card-image-container">

                                            </div>
                                            <div className="guide-article-card-details-container">
                                                <div className="guide-article-card-category">
                                                    {guide.category}
                                                </div>
                                                <div className="guide-article-card-title-container">
                                                    <div className="guide-article-card-title">
                                                        {guide.title}
                                                    </div>
                                                    <div className="guide-article-card-arrow">
                                                        <img src={next_arrow} alt="Card Link Arrow"/>
                                                    </div>
                                                </div>
                                                <div className="guide-article-card-description">
                                                    {guide.description}
                                                </div>
                                            </div>

                                        </div>
                                    </Link>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </>
    )
}

export default GuideGuideSubpage