import http_api from "./http/http_api";

const getSharedWithMe = () => http_api.get("/44968d3ddb237d5c8c9320019/shared-with-me");

const addSharingByEmail = (email) => http_api.post("/44968d3ddb237d5c8c9320019/share", {
    "shared_with_email": email
});
const removeSharingByEmail = (email) => http_api.delete("/44968d3ddb237d5c8c9320019/share_by_email/" + email);


const addSharingLaterByEmail = (email) => http_api.post("/44968d3ddb237d5c8c9320019/share_later", {
    "shared_with_email": email
});
const removeSharingLaterByEmail = (email) => http_api.delete("/44968d3ddb237d5c8c9320019/share_later_by_email/" + email);


const getOtherUser = (user_id) => http_api.get("/44968d3ddb237d5c8c9320019/profile/" + user_id);
const getOthersContacts = (user_id) => http_api.get("/44968d3ddb237d5c8c9320019/other_contacts/" + user_id);
const getOthersAccounts = (user_id) => http_api.get("/44968d3ddb237d5c8c9320019/account/" + user_id)
const getOthersDocuments = (user_id) => http_api.get("/44968d3ddb237d5c8c9320019/documents/other/" + user_id)
const getOthersRegularTransactions = (user_id) => http_api.get("/44968d3ddb237d5c8c9320019/regular-transactions/" + user_id)

export default {
    getSharedWithMe,
    addSharingByEmail,
    removeSharingByEmail,
    getOthersContacts,
    getOthersAccounts,
    getOthersDocuments,
    getOthersRegularTransactions,
    getOtherUser,
    addSharingLaterByEmail,
    removeSharingLaterByEmail
}