import {Link} from "react-router-dom";
import {useEffect, useState} from "react";

import knowledge_api from "../../api/knowledge"

import arrow from "../../assets/blog-card-arrow-light.webp"


const GuidesSubpage = () => {

    const [guides, setGuides] = useState()

    useEffect(() => {
        getArticles()
    }, [])

    const getArticles = () => {
        let response = knowledge_api.getGuides();
        response.then(
            response => {
                setGuides(response.data.articles)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
            <div className="guide-page-container">
                <div className="guide-heading-container">
                    <div className="guide-heading">
                        Knowledge hub
                    </div>
                    <div className="guide-description">
                        All the knowledge on how to use the Snapshot platform.
                    </div>
                </div>
                <div className="guide-articles-container">
                        {guides && guides.map((guide, idx) => {
                            return (
                                <div className="col">
                                    <Link to={"/guide/" + guide.link} className="link">
                                        <div className="guide-article-card-container">
                                            <div className="guide-article-card-image-container">
                                                <img src={guide && guide.image} alt={"Hero Image"} />
                                            </div>
                                            <div className="guide-article-card-details-container">
                                                <div className="guide-article-card-category">
                                                    {guide.category}
                                                </div>
                                                <div className="guide-article-card-title-container">
                                                    <div className="guide-article-card-title">
                                                        {guide.title}
                                                    </div>
                                                    <div className="guide-article-card-arrow">
                                                        <img src={arrow} alt="Card Link Arrow"/>
                                                    </div>
                                                </div>
                                                <div className="guide-article-card-description">
                                                    {guide.description}
                                                </div>
                                            </div>

                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                </div>
            </div>
        </>
    )
}

export default GuidesSubpage