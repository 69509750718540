import revolut from "../assets/bankLogos/revolut.webp"
import halifax from "../assets/bankLogos/halifax.webp"
import nationwide from "../assets/bankLogos/nationwide.webp"
import hsbc from "../assets/bankLogos/hsbc.webp"
import monzo from "../assets/bankLogos/monzo.webp"
import barclays from "../assets/bankLogos/barclays.webp"
import capital_one from "../assets/bankLogos/capitalOne.webp"
import starling from "../assets/bankLogos/starling.webp"
import lloyds from "../assets/bankLogos/lloyds.webp"

const BANK_LOGOS = {
    "Revolut": revolut,
    "Halifax": halifax,
    "Nationwide": nationwide,
    "HSBC": hsbc,
    "Monzo": monzo,
    "Barclays": barclays,
    "Capital One": capital_one,
    "Starling": starling,
    "Lloyds": lloyds
};

export default BANK_LOGOS