
const HomepageFeatures = () => {
    return (
        <div className="home-page-features-container">
            <a className="anchor" id="features" />
            <div className="home-page-features-title">
                A real-time view of your life, right now
            </div>
            <div className="home-page-features-description">
                No more bits of paper in the draw, notes on your phone, excel sheets or files on the shelf.
                We put all your details on one easy-to-report for your loved ones
            </div>

        </div>
    )
}

export default HomepageFeatures