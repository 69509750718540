import {useEffect, useState} from "react";
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';

import download from "../assets/reports/download.webp"

import reportMiddleman from "../utils/reportMiddleman";
import createReport from "../utils/createReport";

const UserReport = (props) => {

    const [report, setReport] = useState();

    const [user, setUser] = useState()

    useEffect(() => {
        setReport(undefined)
        if (!props.user) return;
        setUser(props.user)
        reportMiddleman(props.user.user_id_shared_with_me).then((data) => {
            setReport(createReport.generateExecutorDocument(data.transactions, data.contacts, data.user,
                data.accounts, data.documents))
        })
    }, [props.user])

    return (
        <div className="reports-subpage-preview-container executor">
            {report && !report ? null : <PDFViewer className="report-viewer">{report && report}</PDFViewer> }
            <div className="report-transparent-cover">
                <div className="report-description">
                    {user &&
                        user.first_name + " " + user.last_name
                        + " has nominated you to receive a Snapshot report of his important documents and finances."}
                </div>
                <div className="report-description">
                    {user && "You can access " + user.first_name + "’s snapshot now."}
                </div>
                {report && report ?
                    <PDFDownloadLink document={report} fileName="executor_report.pdf"
                                     className="report-download-button link">
                        <img src={download} alt="Download"/>
                        Download your Executor report
                    </PDFDownloadLink>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default UserReport