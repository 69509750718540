import http_api from "./http/http_api";

const getGuides = () => http_api.get("/44968d3ddb237d5c8c9320019/all_guides")
const getSomeGuides = (amount) => http_api.get("/44968d3ddb237d5c8c9320019/guides/" + amount)
const getGuide = (link) => http_api.get("/44968d3ddb237d5c8c9320019/guide/" + link)
const getRelatedGuides = (category) => http_api.get("/44968d3ddb237d5c8c9320019/similar-guides/" + category)

export default {
    getGuides,
    getGuide,
    getRelatedGuides,
    getSomeGuides
}