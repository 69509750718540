
import mail from "../assets/mail.webp"
import phone from "../assets/phone.webp"
import close from "../assets/close.webp"

const ContactUsPopup = (props) => {

    const toggleMenu = props.toggle

    return (
        <>
            <div className="page-mask" />
            <div className="side-nav-pop-up-container">
                <div className="pop-up-close-container">
                    <img src={close} alt={"Close"} onClick={toggleMenu} className="link"/>
                </div>
                <div className="pop-up-title-container">
                    <div className="popup-title">
                        Contact us
                    </div>
                </div>
                <div className="pop-up-description-container">
                    <div className="popup-description">
                        We're here to help. Get in touch with our team for any questions or concerns.
                    </div>
                </div>
                <div className="contact-us-phone-number-container">
                    <img src={phone} alt={"Phone"} />
                    <div className="phone-number">
                        020 888 888 888
                    </div>
                </div>
                <div className="contact-us-email-container">
                    <img src={mail} alt={"Phone"} />
                    <div className="email-address">
                        snapshot@support.com
                    </div>
                </div>
            </div>
        </>
    )

}

export default ContactUsPopup
