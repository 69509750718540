import {useEffect, useState} from "react";
import dropdown from "../assets/dropdown.webp";
import contacts_api from "../api/contacts";
import sharing_api from "../api/sharing";
import deleteImage from "../assets/delete.webp";
import GenericDialog from "./GenericDialog";
import inquiry from "../assets/inquiry.webp";

const AddContact = (props) => {

    const relationships = [
        "Accountant",
        "Children",
        "Executors",
        "Financial Advisor",
        "Friend",
        "Next Of Kin",
        "Solicitor",
        "Spouse or Partner",
        "Work",
        "Other"
    ]

    const SHARE_NOW = 1;
    const SHARE_LATER = 2;

    const [contactID, setContactID] = useState()
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [relationship, setRelationship] = useState(relationships[0]);
    const [share, setShare] = useState(false)
    const [shareLater, setShareLater] = useState(false)
    const [hasAccount, setHasAccount] = useState()

    const [hasSharing, setHasSharing] = useState(false)
    const [hasShareLater, setHasShareLater] = useState(false)

    const refreshContacts = props.refreshContacts
    const hideContact = props.hideContact

    const [displayConfirmation, setDisplayConfirmation] = useState(false)
    const [showInfoDialog, setShowInfoDialog] = useState(false);
    const [info, setInfo] = useState([]);
    const [heading, setHeading] = useState("");
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const okButton = [{"text":"OK", "id": "1"}];
    const [error, setError] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const delButtons = [{"text":"Cancel", "id": "1"},{"text":"Remove contact", "id": "2"}];
    const [showPermissionDialog, setShowPermissionDialog] = useState(false);
    const permButtons = [{"text":"Cancel", "id": "1"},{"text":"Change permissions", "id": "2"}];
    const [permissionAction, setPermissionAction] = useState(0);


    const handleSubmit = (event) => {
        event.preventDefault();
        if (!contactID) AddContact();
        else UpdateContact();
        return true;
    }

    const handleDelete = (event) => {
        if (event.target.id !== "delete") return;
        setHeading("Are you sure you want to remove this contact?");
        setInfo(["This action cannot be undone."]);
        setShowDeleteDialog(true);
    }

    const handleDeleteDialogResult = (selection) => {
        setShowDeleteDialog(false);
        if (selection === "2")
            DeleteContact();
    }

    const handlePermissionDialogResult = (selection) => {
        setShowPermissionDialog(false);
        if (selection === "2") {
            if (permissionAction === SHARE_NOW) {
                setShare(!share);
            }
            if (permissionAction === SHARE_LATER) {
                setShareLater(!shareLater);
            }
        }
    }

    const handleShareLaterClick = (ev) => {
        if (!shareLater){
            setHeading("Are you sure you want this person to have access to your Snapshot report when you die?");
            setInfo(["This action can be undone by unchecking the tick box"]);
            setShowPermissionDialog(true);
            setPermissionAction(SHARE_LATER);
        }
        else {
            setHeading("Are you sure you want to remove their access to your Snapshot report when you die?");
            setInfo(["This action can be undone by checking the tick box"]);
            setShowPermissionDialog(true);
            setPermissionAction(SHARE_LATER);
        }

    }

    const handleShareClick = (ev) => {
        if (!share) {
            setHeading("Are you sure you want this person to have access to your Snapshot report now?");
            setInfo(["This action can be undone by unchecking the tick box"]);
            setShowPermissionDialog(true);
            setPermissionAction(SHARE_NOW);
        }
        else {
            setHeading("Are you sure you want remove their access to your Snapshot report now?");
            setInfo(["This action can be undone by checking the tick box"]);
            setShowPermissionDialog(true);
            setPermissionAction(SHARE_NOW);
        }

    }


    const AddContact = () => {
        let formData = {
            "email": email,
            "first_name": firstName,
            "last_name": lastName,
            "relationship": relationship,
            "phone": mobileNumber
        }
        let response = contacts_api.postContact(formData);
        response.then(
            response => {
                if (share) addToShares(email)
                if (shareLater) addToShareLater(email)
                setHeading("Adding contact");
                setInfo(["Successful."]);
                setShowInfoDialog(true);
                refreshData()
            }
        ).catch((err) => {
            setHeading("FAILED");
            setError(["Failed to add contact information."]);
            setShowErrorDialog(true);
            console.log(err)
        })
    }

    const UpdateContact = () => {
        let formData = {
            "contactId": contactID,
            "email": email,
            "first_name": firstName,
            "last_name": lastName,
            "relationship": relationship,
            "phone": mobileNumber
        }
        let response = contacts_api.editContact(formData);
        response.then(
            response => {
                if (share && !hasSharing) addToShares(email)
                else if (hasSharing && !share) removeFromShares(email)
                if (shareLater && !hasShareLater) addToShareLater(email)
                else if (hasShareLater && !shareLater) removeFromShareLater(email)
                setHeading("Updating Contact");
                setInfo(["Successful."]);
                setShowInfoDialog(true);
            }
        ).catch((err) => {
            setHeading("FAILED");
            setError(["Failed to update contact information."]);
            setShowErrorDialog(true);
            console.log(err)
        })
    }

    const DeleteContact = () => {
        let response_sharing = sharing_api.removeSharingByEmail(email);
        response_sharing.then(
            response_sharing => {
                let response_sharing_later = sharing_api.removeSharingLaterByEmail(email);
                response_sharing_later.then(
                    response_sharing_later => {
                        let response = contacts_api.deleteContact(contactID);
                        response.then(
                            response => {
                                setHeading("Deleting contact");
                                setInfo(["Successful."]);
                                setShowInfoDialog(true);
                                refreshContacts()
                            }
                        ).catch((err) => {
                            setHeading("FAILED");
                            setError(["Deleting contact."]);
                            setShowErrorDialog(true);
                            console.log(err)
                        })
                    }
                ).catch((err) => {
                    setHeading("FAILED");
                    setError(["Removing share later from contact."]);
                    setShowErrorDialog(true);
                    console.log(err)
                })
            }
        ).catch((err) => {
            setHeading("FAILED");
            setError(["Removing share from contact."]);
            setShowErrorDialog(true);
            console.log(err)
        })

    }

    const addToShares = (email) => {
        let response = sharing_api.addSharingByEmail(email);
        response.then(
            response => {
                refreshData()
            }
        ).catch((err) => {
            setHeading("FAILED");
            setError(["Sharing with contact failed."]);
            setShowErrorDialog(true);
            refreshData()
        })
    }

    const removeFromShares = (email) => {
        let response = sharing_api.removeSharingByEmail(email);
        response.then(
            response => {
                refreshData()
            }
        ).catch((err) => {
            setHeading("FAILED");
            setError(["Removing share from contact failed."]);
            setShowErrorDialog(true);
            refreshData()
        })
    }

    const addToShareLater = (email) => {
        let response = sharing_api.addSharingLaterByEmail(email);
        response.then(
            response => {
                refreshData()
            }
        ).catch((err) => {
            setHeading("FAILED");
            setError(["Sharing later with contact failed."]);
            setShowErrorDialog(true);
            refreshData()
        })
    }

    const removeFromShareLater = (email) => {
        let response = sharing_api.removeSharingLaterByEmail(email);
        response.then(
            response => {
                refreshData()
            }
        ).catch((err) => {
            setHeading("FAILED");
            setError(["Removing Share later from contact failed."]);
            setShowErrorDialog(true);
            refreshData()
        })
    }

    const handleOKDialogResult = () => {
        setShowInfoDialog(false);
        setShowErrorDialog(false);
        hideContact();
    }

    const refreshData = () => {
        refreshContacts()
    }


    useEffect(() => {
        if (props.contact){
            setContactID(props.contact.id)
            setFirstName(props.contact.first_name)
            setLastName(props.contact.last_name)
            setEmail(props.contact.email)
            setMobileNumber(props.contact.phone)
            setRelationship(props.contact.relationship)
            setHasAccount(props.contact.hasAccount)

            setShare(props.contact.hasShareRights)
            setHasSharing(props.contact.hasShareRights)
            setShareLater(props.contact.hasShareLaterRights)
            setHasShareLater(props.contact.hasShareLaterRights)
        }
    }, [props.contact])

    return (
        <>
            <div className="add-contact-container">
                <div className="add-contact-title">
                    {contactID && contactID ? firstName + " " + lastName : "Add a new contact"}
                </div>
                <div className="add-contact-form-container">
                    <form name="contact-form" onSubmit={handleSubmit}>
                        <div className="add-contact-form-element-container">
                            <label>First name</label>
                            <input id="firstName" type="text" value={firstName} onChange={(ev) => {
                                setFirstName(ev.target.value)
                            }} required />
                        </div>
                        <div className="add-contact-form-element-container">
                            <label>Last name</label>
                            <input id="lastName" type="text" value={lastName} onChange={(ev) => {
                                setLastName(ev.target.value)
                            }} required />
                        </div>
                        <div className="add-contact-form-element-container">
                            <label>Email address</label>
                            <input id="email" type="email" value={email} onChange={(ev) => {
                                setEmail(ev.target.value)
                            }} required disabled={hasSharing || hasShareLater}/>
                        </div>
                        <div className="add-contact-form-row-container">
                            <div className="add-contact-form-element-container-multiple col-sm-5">
                                <label>Mobile number</label>
                                <input id="mobile" type="tel" value={mobileNumber} onChange={(ev) => {
                                    setMobileNumber(ev.target.value)
                                }} required pattern="(\+)?[0-9]+"
                                       title="Must be a valid phone number, no spaces, international dialling code is optional"/>
                            </div>
                            <div className="add-contact-form-element-container-multiple col-sm-6">
                                <label>Relationship</label>
                                <div className="add-contact-form-input">
                                    <select formControlName="periodicity" className="add-contact-form-dropdown"
                                            onChange={(ev) => setRelationship(ev.target.value)} required>
                                        {relationships && relationships.map((r, idx) => {
                                            return (
                                                <option className="add-contact-form-dropdown-items" value={r}
                                                        selected={contactID && relationship === r}>
                                                    {r}
                                                </option>
                                            )
                                        })}
                                    </select>
                                    <img src={dropdown} alt/>
                                </div>
                            </div>
                        </div>
                        <div className="add-contact-permissions-title">
                            Permissions
                        </div>
                        <div className="add-contact-have-access-later-container">
                            <input type="checkbox" onChange={handleShareLaterClick} checked={shareLater} />
                            <div className="add-contact-have-access-later-text">
                                I want this person to have access to my Snapshot report when I die.
                            </div>
                        </div>
                        <div className="add-contact-have-access-now-container">
                            <input type="checkbox" onChange={handleShareClick} checked={share} />
                            <div className="add-contact-have-access-now-text">
                                I want this person to have access to my documents now.
                            </div>
                        </div>
                        <div className="add-contact-have-account-message">
                            {hasAccount && hasAccount ? null : "This user has not yet created a Snapshot account"}
                        </div>
                        <div className="add-contact-buttons-container">
                            <div className="add-contact-submit-button-container">
                                <input type="submit" value={contactID && contactID ? "Save changes" : "Add Contact"}
                                       className="add-contact-button"/>
                            </div>
                            {contactID && contactID ?
                                <div className="add-contact-delete-button link" id="delete" onClick={handleDelete}>
                                    Delete contact
                                </div>
                                :
                                null
                            }
                        </div>
                    </form>
                </div>
            </div>
            {showInfoDialog ?
                <GenericDialog title="Contact" text={info} heading={heading}
                               buttons={okButton} result={handleOKDialogResult.bind(this)}
                /> : null }
            {showErrorDialog ?
                <GenericDialog title="Contact - Error" text={error} heading={heading} buttons={okButton}
                               result={handleOKDialogResult.bind(this)} /> : null }
            {showDeleteDialog ?
                <GenericDialog title="Contact" text={info} buttons={delButtons} icon={deleteImage} heading={heading}
                               result={handleDeleteDialogResult.bind(this)} /> : null }
            {showPermissionDialog ?
                <GenericDialog title="Contact" text={info} heading={heading} icon={inquiry}
                               buttons={permButtons} result={handlePermissionDialogResult.bind(this)} /> : null }
        </>

    )
}

export default AddContact