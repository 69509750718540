import http_api from "./http/http_api";

const SendGetInTouch = (formData) => http_api.post("/get-in-touch", formData)

const getBlogs = () => http_api.get("/blogs")
const getBlog = (link) => http_api.get("/blogs/" + link)
const getRelatedBlogs = (category) => http_api.get("/similar-blogs/" + category)

export default {
    SendGetInTouch,
    getBlog,
    getBlogs,
    getRelatedBlogs
}