import SideNav from "../../components/SideNav";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

import DOCUMENTS from "../../models/Documents";
import AddDocument from "../../components/AddDocument";
import arrow from "../../assets/arrow.webp";
import documents_api from "../../api/documents";
import file from "../../assets/file.webp"
import dateUtils from "../../utils/dateUtils";

const DocumentsSection = () => {
    let params = useParams();

    const [section, setSection] = useState()
    const [documents, setDocuments] = useState()

    const [onDocument, setOnDocument] = useState(false)
    const [inspectingDocument, setInspectingDocument] = useState()

    useEffect(() => {
        if (!params.type) return;
        setSection(DOCUMENTS.LINKS[params.type])
    }, [params.type])

    useEffect(() => {
        GetDocuments()
    }, [])

    const GetDocuments = async () => {
        let response = documents_api.getDocument(params.type);
        response.then(
            response => {
                setDocuments(response.data.documents)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    const handleDocumentClick = (ev) => {
        if (!ev.target.id && ev.target.id !== 0) return
        setInspectingDocument(documents[ev.target.id])
        setOnDocument(true)
    }

    const handleAdd = (ev) => {
        if (ev.target.id !== "add") return
        setOnDocument(true)
        setInspectingDocument(null)
    }

    const handleHideDocument = (ev) => {
        if (ev.target.id !== "hide") return
        hideDocument()
    }

    const hideDocument = () => {
        setOnDocument(false)
        setInspectingDocument(null)
    }

    const shortenDescription = (desc) => {
        if (desc.length > 50) {
            let shortenDesc = ""
            let words = desc.split(" ")
            words.forEach((word) => {
                if ((shortenDesc + " " + word).length <= 37) shortenDesc += " " + word
                else {
                    return shortenDesc + " (...)"
                }
            })
            return shortenDesc + " (...)"
        }
        else return desc
    }

    return (
        <div className="page-container">
            <SideNav />
            <div className="documents-subpage-container">
                {onDocument && onDocument ?
                    <div className="individual-contact-page-container">
                        <div className="individual-contact-page-title-container">
                            <img className="contact-page-back-button link" src={arrow} id="hide"
                                 onClick={handleHideDocument}/>
                            <div className="contact-page-title">{section && section.title}</div>
                        </div>
                        <div className="add-contact-component-container">
                            <AddDocument document={inspectingDocument} refreshDocuments={GetDocuments.bind(this)}
                                         hideDocument={hideDocument.bind(this)} documentType={section && section.link}
                                        documentTitle={section && section.word}/>
                        </div>
                    </div>
                    :
                    <div className="individual-documents-section-container">
                        {section ?
                        <div className="documents-page-breadcrumb-container">
                            <div className="documents-page-breadcrumb">
                                <a href={"/dashboard#" + section.title}>
                                    <div className="back">&lt; Back</div>
                                </a>
                            </div>
                        </div> : null }
                        <div className="documents-section-container">
                            <div className={section && "documents-section-image-container " + section.colour}>
                                <img src={section && section.image} alt={"section logo image"}/>
                            </div>
                            <div className="documents-section-info-container">
                                <div className="documents-section-info-title">{section && section.title}</div>
                                <div
                                    className="documents-section-info-description">{section && section.description}</div>
                            </div>
                            <button className="documents-add-button" id="add" onClick={handleAdd}>
                                <div className="documents-add-button-text"
                                     id="add">Add</div>
                            </button>
                        </div>
                        <div className="current-documents-display-container">
                            <div className="row">
                            {documents && documents.map((document, idx) => {
                                return (
                                    <div className="document-card-container col-sm-4">
                                        <div className="document-card link" onClick={handleDocumentClick} id={idx}>
                                            <div className="document-card-image-container" id={idx}>
                                                <img src={file} id={idx}/>
                                            </div>
                                            <div className="document-card-text-container" id={idx}>
                                                <div className="document-card-name" id={idx}>
                                                    {document.title}
                                                </div>
                                                <div className="document-card-info" id={idx}>
                                                    {document.value}
                                                </div>
                                                <div className="document-card-info" id={idx}>
                                                    {document.policy_number}
                                                </div>
                                                <div className="document-card-info" id={idx}>
                                                    {document.description ? shortenDescription(document.description) : ""}
                                                </div>
                                                <div className="document-card-info" id={idx}>
                                                    {"Updated: " + dateUtils.formatDateLong(new Date(document.updated_at))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
export default DocumentsSection