const GenericDialog = (props) => {
    const title = props.title;
    const heading = props.heading;
    const icon = props.icon;
    const text = props.text;
    const buttons = props.buttons;
    const result = props.result;

    const handleButtonClick = (ev) => {
        if (!ev) return;
        result(ev.currentTarget.id);
    }

    return (
        <>
            <div className="page-mask"/>
            <div className="dialog-container">
                <div className="dialog-header-container">
                    <div className="dialog-header">
                        {icon ?
                        <img src={icon} alt="Dialog icon" /> : null }
                        {title}
                    </div>
                    <div className="dialog-content-heading">
                        {heading}
                    </div>
                    {text && text.map((item, idx) => {
                        return (
                            <>
                                <div className="dialog-text">
                                    {item}
                                </div>
                            </>
                        )
                    })
                    }
                    <div className="dialog-footer-container">
                        {buttons && buttons.map((button, idx) => {
                            return (
                                <>
                                    <div className="dialog-button link" onClick={handleButtonClick} id={button.id}>
                                        <div className="dialog-button-text">{button.text}</div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default GenericDialog