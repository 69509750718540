import http_api from "./http/http_api";
import http_api_form from "./http/http_api_form";

const getDocument = (type) => http_api.get("/44968d3ddb237d5c8c9320019/documents/" + type);

const getDocuments = () => http_api.get("/44968d3ddb237d5c8c9320019/documents");

const postDocument = (data) => http_api_form.post("/44968d3ddb237d5c8c9320019/add/document", data);

const editDocument = (data) => http_api.put("/44968d3ddb237d5c8c9320019/update/document", data);

const deleteDocument = (contact_id) => http_api.delete("/44968d3ddb237d5c8c9320019/delete/document/" + contact_id);

// const downloadDocument = (document) => http_api_form.post("/44968d3ddb237d5c8c9320019/download/document", document);

const getDashboard = () => http_api.get("/44968d3ddb237d5c8c9320019/documents-dashboard")

export default {
    getDocuments,
    getDocument,
    postDocument,
    editDocument,
    deleteDocument,
    // downloadDocument,
    getDashboard
}