import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import logo from "../assets/logo.webp";

import complete from "../assets/complete.webp"
import incomplete from "../assets/incomplete.webp"

const RegisterPageProgress = (props) => {
    const navigate = useNavigate()

    const [page, setPage] = useState()
    const [plus, setPlus] = useState(false)

    const sections = [
        {"title": "Your details", "description": "Please provide your user information.", "page": 0},
        {"title": "Email verification", "description": "Verify your email so we know it’s you.", "page": 1},
        {"title": "Mobile verification", "description": "Verify your mobile so we know it’s you.", "page": 2},
        {"title": "Two Factor Authentication", "description": "Enable and configure two factor authentication.", "page": 3},
        {"title": "Next of Kin", "description": "Add the important person you’ll share your information with.", "page": 4},
    ]

    const plusInfo = {"title": "Snapshot Plus", "description": "Get more out of Snapshot", "page": 5}

    useEffect(() => {
        setPage(props.page)
    }, [props.page])

    useEffect(() => {
        setPlus(props.plus)
    }, [props.plus])

    return (
        <>
            <div className="register-page-progress-container">
                <div className="register-image-snapshot-logo link">
                    <img tabIndex="0" src={logo} alt="Snapshot Logo"
                         onClick={(ev) => {navigate("/home")}} />
                </div>
                <div className="register-progress-container">
                    {sections && sections.map((section, idx) => {
                        return (
                            <div className="register-section-container">
                                <div className="register-section-image-container">
                                    <img src={page > section.page ? complete : incomplete} />
                                </div>
                                <div className="register-section-text-container">
                                    <div className={"register-section-title" + (page > section.page ? " complete" : "")}>
                                        {section.title}
                                    </div>
                                    <div className={"register-section-description" + (page > section.page ? " complete" : "")}>
                                        {section.description}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className="register-section-container">
                        <div className="register-section-image-container">
                            <img src={plus && page > plusInfo.page && plus ? complete : incomplete} />
                        </div>
                        <div className="register-section-text-container">
                            <div className={"register-section-title" + (plus && page > plusInfo.page && plus ? " complete" : "")}>
                                {plusInfo.title}
                            </div>
                            <div className={"register-section-description" + (plus && page > plusInfo.page && plus ? " complete" : "")}>
                                {plusInfo.description}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="register-progress-email">
                    support@snapshot.com
                </div>
            </div>
        </>
    )
}

export default RegisterPageProgress