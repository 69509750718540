import SideNav from "../../components/SideNav";


const Privacy = () => {
    return (
        <div className="page-container">
            <SideNav />
            <div className="privacy-subpage-container">
                <div className="privacy-title-container">
                    <div className="privacy-title">
                        Privacy statement
                    </div>
                </div>
                <div className="privacy-body-container">
                    At Snapshot, we respect your privacy and are committed to protecting your personal information. This Privacy Statement explains how we collect, use, disclose, and protect your personal information.
                    <br></br><br></br>
                    Personal Information We Collect
                    <br></br><br></br>
                    We may collect personal information from you, such as your name, email address, postal address, phone number, or other information that can be used to identify you. We may collect this information when you:
                    <ul>
                        <li>Sign up for our newsletter or promotional emails</li>
                        <li>Contact us with a question or inquiry</li>
                        <li>Place an order on our website</li>
                        <li>Participate in a survey or contest</li>
                        <li>Use our website or mobile app</li>
                    </ul>
                    <br></br>
                    How We Use Your Personal Information
                    <br></br><br></br>
                    We may use your personal information for the following purposes:
                    <ul>
                        <li> To process and fulfill your orders</li>
                        <li>To communicate with you about your orders, account, or inquiries</li>
                        <li>To send you promotional emails or newsletters that you have opted in to receive</li>
                        <li>To improve our products and services</li>
                        <li>To conduct research and analysis</li>
                        <li>To comply with legal obligations</li>
                    </ul>
                    <br></br>
                    How We Protect Your Personal Information
                    <br></br><br></br>
                    We take reasonable steps to protect your personal information from unauthorized access, use, or disclosure. We use a variety of security measures, including encryption and authentication tools, to protect your personal information.
                    <br></br><br></br>
                    Sharing Your Personal Information
                    We may share your personal information with third-party service providers who perform services on our behalf, such as payment processing, shipping, or email marketing. We require these service providers to use your personal information only to provide services to us and to protect your personal information in accordance with this Privacy Statement.
                    <br></br><br></br>
                    We may also share your personal information as required by law or to comply with legal process, or in connection with a merger, acquisition, or sale of all or a portion of our business.
                    <br></br><br></br>
                    Your Choices Regarding Your Personal Information
                    You may choose to opt out of receiving promotional emails or newsletters from us by following the instructions in the emails. You may also update or correct your personal information by contacting us.
                    <br></br><br></br>
                    Contact Us
                    If you have any questions or concerns about our privacy practices, please contact us at <a href="mailto:support@snapshot.life">support@snapshot.life</a>
                </div>
            </div>
        </div>
    )
}

export default Privacy
