import {useEffect, useState} from "react";
import contacts_api from "../../api/contacts";
import SideNav from "../../components/SideNav";

import add from "../../assets/add_light.webp";
import arrow from "../../assets/arrow.webp"
import AddContact from "../../components/AddContact";

const Contacts = () => {

    const [onContact, setOnContact] = useState(false)
    const [contacts, setContacts] = useState()

    const [inspectingContact, setInspectingContact] = useState()

    useEffect(() => {
        GetContacts()
    }, [])

    const GetContacts = () => {
        let response = contacts_api.getContacts();
        response.then(
            response => {
                setContacts(response.data.contacts)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    const handleAddContact = (ev) => {
        if (ev.target.id !== "add") return;
        setInspectingContact(undefined)
        setOnContact(true)
    }

    const handleInspectContact = (ev) => {
        if (!contacts) return;
        if (!ev.target.id && ev.target.id !== 0) return
        setInspectingContact(contacts[ev.target.id])
        setOnContact(true)
    }

    const hideContact = (ev) => {
        setOnContact(false)
        setInspectingContact(undefined)
    }

    return (
        <div className="page-container">
            <SideNav />
            <div className="contacts-subpage-container">
                {onContact && onContact ?
                    <div className="individual-contact-page-container">
                        <div className="individual-contact-page-title-container">
                            <img className="contact-page-back-button link" src={arrow} id="hide" onClick={hideContact} />
                            <div className="contact-page-title">Contacts</div>
                        </div>
                        <div className="add-contact-component-container">
                            <AddContact contact={inspectingContact} refreshContacts={GetContacts.bind(this)}
                            hideContact={hideContact.bind(this)}/>
                        </div>
                    </div>
                    :
                    <div className="contact-page-container">
                        <div className="contacts-page-title-container">
                            <div className="contact-page-title">Contacts</div>
                            <button className="contact-page-add-button" id="add" onClick={handleAddContact}>
                                <div className="contact-page-add-button-text" id="add">Add</div>
                            </button>
                        </div>
                        <div className="contacts-page-contacts-container">
                            {contacts && contacts.length === 0 ?
                                <div className="contact-page-description">
                                    Empower your next of kin: Add your key contacts here.
                                </div>
                                :
                                <div className="contacts-page-contacts-grid">
                                    <div className="row">
                                        {contacts && contacts.map((contact, idx) => {
                                            return (
                                                <div className="col-sm-3 contact-card"
                                                     onClick={handleInspectContact} id={idx}>
                                                    <div className="contact-card-top-container" id={idx}>
                                                        <div className="contact-profile-picture-container" id={idx}>
                                                            <div className="contact-profile-picture-letters" id={idx}>
                                                                {contact && contact.first_name.charAt(0).toUpperCase()
                                                                    + contact.last_name.charAt(0).toUpperCase()}
                                                            </div>
                                                        </div>
                                                        <div className="contact-card-name" id={idx}>
                                                            {contact &&
                                                                contact.first_name.charAt(0).toUpperCase() +
                                                                contact.first_name.slice(1) +
                                                                " " +
                                                                contact.last_name.charAt(0).toUpperCase() +
                                                                contact.last_name.slice(1)
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="contact-card-bottom-container" id={idx}>
                                                        <div className="contact-card-relationship" id={idx}>
                                                            {contact && contact.relationship}
                                                        </div>
                                                        <div className="contact-card-email" id={idx}>
                                                            {contact && contact.email}
                                                        </div>
                                                        <div className="contact-card-phone-number" id={idx}>
                                                            {contact && contact.phone}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default Contacts