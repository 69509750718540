import login_image from "../assets/login_image.webp";
import logo from "../assets/logo.webp";
import {useNavigate} from "react-router-dom";


const LoginRegisterSideImage = () => {
    const navigate = useNavigate()

    return (
        <>
            <div className="login-page-image-container">
                <img src={login_image} alt="Snapshot User" />
                <div tabIndex="0" className="login-image-snapshot-logo link">
                    <img src={logo} alt="Snapshot Logo"
                         onClick={(ev) => {navigate("/home")}} />
                </div>
                <div className="login-image-quote-container">
                    <div className="login-image-quote">
                        <div className="quote">Snapshot makes me feel</div>
                        <div className="quote-highlight">relieved</div>
                        <div className="quote">knowing my loved ones will have</div>
                        <div className="quote-highlight">everything they need.</div>
                    </div>
                    <div className="login-image-quoter">
                        Jane
                    </div>
                    <div className="login-image-quoter-description">
                        Mum of 2
                    </div>
                </div>
            </div>
        </>
    )
}


export default LoginRegisterSideImage