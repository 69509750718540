import {useEffect, useState} from "react";

import hide_transaction from "../assets/hide-transaction.webp"
import GenericDialog from "./GenericDialog";

const TransactionHideConfirmation = (props) => {

    const closeDisplay = props.closeDisplay
    const hideTransaction = props.hideTransaction
    const info = [
        "Your next of kin will not see this transaction on the report they receive.",
        "If you change your mind, you can undo this action in your profile:",
        "Go to Profile > see hidden outgoings"
    ]
    const heading = "Are you sure you want to hide this from the Executor report?"
    const buttons = [{"text":"Cancel", "id": "1"},{"text":"Hide outgoing", "id": "2"}]

    const [display, setDisplay] = useState()

    useEffect(() => {
        setDisplay(props.display)
    }, [props.display])

    const handleDialogResult = (selection) => {
        if (selection === "1")
            closeDisplay();
        if (selection === "2")
            hideTransaction();
    };

    return (
        <>
            {display ?
                <GenericDialog title="Hide Outgoings" text={info} heading={heading} icon={hide_transaction}
                               buttons={buttons} result={handleDialogResult.bind(this)}
                /> : null }
        </>
    )

}

export default TransactionHideConfirmation