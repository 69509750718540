import {useEffect, useState} from "react";
import users_api from "../../api/users";
import LoginRegisterSideImage from "../../components/LoginRegisterSideImage";
import {useNavigate} from "react-router-dom";

const UpdateMFA = (props) => {
    const navigate = useNavigate();
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")

    const [verificationLetter1, setVerificationLetter1] = useState("")
    const [verificationLetter2, setVerificationLetter2] = useState("")
    const [verificationLetter3, setVerificationLetter3] = useState("")
    const [verificationLetter4, setVerificationLetter4] = useState("")
    const [verificationLetter5, setVerificationLetter5] = useState("")
    const [verificationLetter6, setVerificationLetter6] = useState("")

    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const enable = props.enable;

    useEffect(() => {
        GetUser()
    }, [])

    useEffect(() => {
        SendUserText(false);
    }, [phone])

    const handleVerify = (event) => {
        event.preventDefault();
        setError("")
    }

    const CheckVerificationData = () => {
        let string = (verificationLetter1 + verificationLetter2 + verificationLetter3 + verificationLetter4 + verificationLetter5 + verificationLetter6)
        let lengthCheck = string.length === 6
        return [lengthCheck].every(Boolean);
    }

    const handleVerifySubmit = (event) => {
        if (!CheckVerificationData) setError("The data you have inputted is invalid")
        let verificationCode = (verificationLetter1 + verificationLetter2 + verificationLetter3 + verificationLetter4 + verificationLetter5 + verificationLetter6)
        VerifyUserPhone(verificationCode);
    }

    const GetUser = () => {
        let response = users_api.getProfile();
        response.then(
            response => {
                let user = response.data.profile;
                setEmail(user.email);
                setPhone(user.phone);
            }
        ).catch((err) => {
            setError("Invalid user");
        })
    }

    const VerifyUserPhone = (verificationCode) => {
        if (!verificationCode) return;
        if (!email) return;
        let formData = {
            "apiKey": process.env.REACT_APP_SERVER_API_KEY,
            "code": verificationCode
        }
        let response = users_api.verify2FA(formData);
        response.then(
            response => {
                if (response.data.error) {
                    setError(response.data.error)
                }
                else {
                    let body = {
                        "apiKey": process.env.REACT_APP_SERVER_API_KEY,
                        "code": verificationCode,
                        "email": email,
                        "enable": enable
                    }
                    let response = users_api.update2FA(body);
                    response.then(
                        response => {
                            if (response.data.error) {
                                setMessage("");
                                setError(response.data.error)
                            }
                            else {
                                setError("");
                                setMessage("2FA has been updated.")
                            }
                        }
                    ).catch((err) => {
                        setMessage("");
                        setError("2FA update failed");
                    })

                }
            }
        ).catch((err) => {
            setError("Verification request failed")
            console.log(err)
        })
    }

    const SendUserText = (resend) => {
        if (!phone) return;
        setMessage("");
        setError("");
        let formData = {
            "apiKey": process.env.REACT_APP_SERVER_API_KEY,
            "phone": phone
        }
        let response = users_api.resend2FA(formData);
        response.then(
            response => {
                if (response.data && response.data.error){
                    if (resend)
                        setError("Security Code failed to resend")
                    else
                        setError("Security Code failed to send")
                }
                else if (resend)
                    setMessage("Security Code has been resent")
            }
        ).catch((err) => {
            if (resend)
                setError("Security Code failed to resend")
            else
                setError("Security Code failed to send")
        })
    }


    return (
        <div className="login-page-container">
            <LoginRegisterSideImage />
            <div className="mfa-subpage-container">
                <div className="mfa-page-verification-container" >
                    <form name="mfa-form" onSubmit={handleVerify}>
                        <div className="mfa-page-title">
                            Multi-factor Authentication
                        </div>
                        <div className="mfa-page-subtitle verification">
                            Mobile verification
                        </div>
                        <div className="mfa-page-description verification">
                            We’ve sent a 6 character code to your mobile number for security purposes. The code will expire after 2 minutes.
                        </div>
                        <div className="verify-form-container">
                            <input type='text' id='verify-mob-1' className='verify-form-character' value={verificationLetter1}
                                   maxLength='1' onChange={(ev) => {
                                setVerificationLetter1(ev.target.value)
                                if (ev.target.value !== "") document.getElementById('verify-mob-2').focus();}}/>
                            <input type='text' id='verify-mob-2' className='verify-form-character' value={verificationLetter2}
                                   maxLength='1' onChange={(ev) => {
                                setVerificationLetter2(ev.target.value)
                                if (ev.target.value !== "") document.getElementById('verify-mob-3').focus();}}/>
                            <input type='text' id='verify-mob-3' className='verify-form-character' value={verificationLetter3}
                                   maxLength='1' onChange={(ev) => {
                                setVerificationLetter3(ev.target.value)
                                if (ev.target.value !== "") document.getElementById('verify-mob-4').focus();}}/>
                            <input type='text' id='verify-mob-4' className='verify-form-character' value={verificationLetter4}
                                   maxLength='1' onChange={(ev) => {
                                setVerificationLetter4(ev.target.value)
                                if (ev.target.value !== "") document.getElementById('verify-mob-5').focus();}}/>
                            <input type='text' id='verify-mob-5' className='verify-form-character' value={verificationLetter5}
                                   maxLength='1' onChange={(ev) => {
                                setVerificationLetter5(ev.target.value)
                                if (ev.target.value !== "") document.getElementById('verify-mob-6').focus();}}/>
                            <input type='text' id='verify-mob-6' className='verify-form-character' value={verificationLetter6}
                                   maxLength='1' onChange={(ev) => {
                                setVerificationLetter6(ev.target.value)}}/>
                        </div>

                        <div className="mfa-error-message">
                            {error && error}
                        </div>
                        <div className="mfa-message">
                            {message && message}
                        </div>

                        <div className="verify-submit-button-container">
                            <input type="submit" value="Verify" className="verify-submit-button link"
                                   disabled={!CheckVerificationData()} onClick={handleVerifySubmit}/>
                        </div>

                        <div className="verify-resend-code link" onClick={(ev) => SendUserText(true)}>
                            Resend verification code
                        </div>
                        <div className="return-to-details link" onClick={(ev) => navigate('/profile')}>
                            Return to profile
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UpdateMFA