import LoginRegisterSideImage from "../../components/LoginRegisterSideImage";
import users_api from "../../api/users";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useState} from "react";
import eye_slash from "../../assets/eye-slash.webp";
import eye from "../../assets/eye.webp";
import sanctum_api from "../../api/csrf";

const ResetPassword = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get("email");
    const code = searchParams.get("code");
    const [error, setError] = useState("");
    const [errors, setErrors] = useState([]);
    const [message, setMessage] = useState("");
    const [showPasswords, setShowPasswords] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleSubmit = (ev) => {
        ev.preventDefault();
        Submit();
        return true;
    }
    const Submit = () => {
        setError("");
        setMessage("");
        setErrors([]);
        let token_response = sanctum_api.getToken();
        token_response.then(
            () => {
                let formData = {
                    "apiKey": process.env.REACT_APP_RESET_PASSWORD_API_KEY,
                    "email": email,
                    "code": code,
                    "password": password,
                    "confirm_password": confirmPassword
                }
                let response = users_api.resetPassword(formData);
                response.then(
                    response => {
                        if(response.data.error) {
                            setError(response.data.error)
                        }
                        else if (response.data.errors){
                            setErrors(response.data.errors.password);
                        }
                        else{
                            setMessage("Password updated");
                        }
                    }
                ).catch((err) => {
                    setError("Resetting password failed")
                    console.log(err)
                })
            }
        ).catch((err) => {
            setError("Resetting password failed")
            console.log(err)
        })
    }

    const handlePasswordClick = (ev) => {
        if (!ev) return;
        setShowPassword(!showPassword);
    }

    const handleConfirmPasswordClick = (ev) => {
        if (!ev) return;
        setShowConfirmPassword(!showConfirmPassword);
    }

    return (
        <>
            <div className="login-page-container">
                <LoginRegisterSideImage />
                <div className="mfa-subpage-container">
                    <form name="mfa-form" >
                        <div className="mfa-page-title">
                            Reset password
                        </div>
                        <div className="mfa-page-subtitle verification">
                            Enter new password
                        </div>
                        <div className="reset-form-container">
                            <div className="register-form-element-password-container">
                                <div className="register-form-element-label">Password*</div>
                                <input type={showPassword ? "text" : "password"} required value={password}
                                       onChange={(ev) => {setPassword(ev.target.value)}} />
                                <img tabIndex="0" className="link" src={showPassword ? eye_slash : eye}
                                     alt="Show/Hide Password" onClick={handlePasswordClick}/>
                            </div>

                            <div className="register-form-element-password-container">
                                <div className="register-form-element-label">Confirm password*</div>
                                <input type={showConfirmPassword ? "text" : "password"} required value={confirmPassword}
                                       onChange={(ev) => {setConfirmPassword(ev.target.value)}} />
                                <img tabIndex="0" className="link" src={showConfirmPassword ? eye_slash : eye}
                                     alt="Show/Hide Password" onClick={handleConfirmPasswordClick}/>
                            </div>
                        </div>

                        <div className="mfa-error-message">
                            {error && error}
                        </div>
                        {errors && errors.map((err, idx) => {
                            return (
                                <div className="mfa-error-message">
                                    {err}
                                </div>
                            )
                        })}
                        <div className="mfa-message">
                            {message && message}
                        </div>

                        <div className="verify-submit-button-container">
                            <input type="submit" value="Reset" className="verify-submit-button link"
                                   disabled={!password || !confirmPassword || password != confirmPassword} onClick={handleSubmit}/>
                        </div>
                        <div className="return-to-details link" onClick={(ev) => navigate('/forgot-password')}>
                            Return to forgot password
                        </div>
                        <div className="return-to-details link" onClick={(ev) => navigate('/login')}>
                            Return to login
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ResetPassword

