import auth from "../api/auth";
import { Link } from "react-router-dom";
import logo from "../assets/logo.webp";
import close from "../assets/close.webp";

const HeaderContainer = () => {


    const handleLogout = () => {
        auth.logout("/login")
    }

    function toggleMenu() {
        let element = document.getElementById("burger-menu");
        if (!element) return;
        if (element.classList.contains("open")) {
            element.classList.remove("open");
            element.classList.add("closed");
        }
        else {
            element.classList.add("open");
            element.classList.remove("closed");
        }
    }
    return (
        <>
            <div className="header-container">
                <div className="header-content">
                    <Link to={"/dashboard"}>
                        <div className="header-logo-container">
                            <img src={logo} alt="Snapshot Logo" />
                        </div>
                    </Link>
                    <div className="large-header">
                        <div className="logout-button" id={"logout"} onClick={handleLogout}>Logout</div>
                    </div>
                    <div className="small-header">
                        <div className="home-search-container">
                            <div className="nav-menu">
                                <div className={"burger-icon link "} id="burger-icon" onClick={toggleMenu}>
                                    <span className={"burger-line line1 "} />
                                    <span className={"burger-line line2 "} />
                                    <span className={"burger-line line3 "} />
                                </div>
                            </div>
                        </div>

                        <div className="header-link-container">
                            <div id="small-nav-links" className="nav-links" style={{ display: "block" }}>
                                <div className="burger-menu closed" id="burger-menu">
                                    <div className="burger-menu-header-container">
                                        <Link to="/home" onClick={toggleMenu}>
                                            <img src={logo} alt="Snapshot logo" className="logo" />
                                        </Link>
                                        <div className="burger-icon link" id="burger-icon" onClick={toggleMenu}>
                                            <img src={close} alt="Close logo" className="burger-menu-close" />
                                        </div>
                                    </div>
                                    <div className="burger-menu-sections-container">
                                        <div className="burger-menu-body-container">
                                            <div className="burger-menu-divider" />
                                            <Link to={"/dashboard"} onClick={toggleMenu}>
                                                <div className="burger-item">Dashboard</div>
                                            </Link>
                                            <Link to={"/banking"} onClick={toggleMenu}>
                                                <div className="burger-item">Banking</div>
                                            </Link>
                                            <Link to={"/contacts"} onClick={toggleMenu}>
                                                <div className="burger-item">Contacts</div>
                                            </Link>
                                            <Link to={"/reports"} onClick={toggleMenu}>
                                                <div className="burger-item">Your Reports</div>
                                            </Link>
                                            <div className="burger-menu-divider" />
                                            <Link to={"/executors"} onClick={toggleMenu}>
                                                <div className="burger-item">Your executors</div>
                                            </Link>
                                            <Link to={"/shared-with-you"} onClick={toggleMenu}>
                                                <div className="burger-item">Shared with you</div>
                                            </Link>
                                            <div className="burger-menu-divider" />
                                            <Link to={"/profile"} onClick={toggleMenu}>
                                                <div className="burger-item">Profile</div>
                                            </Link>
                                            <Link to={"/knowledge-hub"} onClick={toggleMenu}>
                                                <div className="burger-item">Knowledge hub</div>
                                            </Link>
                                            <Link to={"/privacy"} onClick={toggleMenu}>
                                                <div className="burger-item">Privacy</div>
                                            </Link>
                                            <div className="burger-menu-divider" />
                                            <Link to={"#"} onClick={() => {
                                                toggleMenu();
                                                handleLogout();
                                            }} id="logout">
                                                <div className="burger-item">Logout</div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </>
    )
}

export default HeaderContainer