import homepage_image from "../../assets/landing-image.webp";

const HomepageLanding = () => {
    return (
        <div className="home-page-landing-container">
            <a className="anchor" id="landing"></a>
            <div className="home-page-landing-text-container">
                <div className="home-page-landing-title">
                    All life’s important details in one place.
                </div>
                <div className="home-page-landing-description">
                    Our mission is to help you organize your life in 3 minutes and quickly put all of your
                    life’s little details in order.
                </div>
            </div>
            <div className="home-page-landing-image-container">
                <img src={homepage_image} alt="Snapshot home page image"/>
            </div>
        </div>
    )
}

export default HomepageLanding