import {useState} from "react";

import close from "../assets/close.webp"
import users_api from "../api/users";
import auth from "../api/auth";

const InviteOthersPopUp = (props) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const [error, setError] = useState("")

    const [subpage, setSubpage] = useState(1)

    const toggleMenu = props.toggle

    const handleInvite = (event) => {
        setError("")
        event.preventDefault();
        SplitName();
    }

    const SplitName = () => {
        let names = name.split(" ")
        if (names.length < 2 || names[1] === "") {
            setError("Please include a First and a Last name")
            return
        }
        let firstName;
        let lastName = "";
        names.map((name, idx) => {
            if (idx === 0) firstName = name
            else lastName += name + " "
        })
        lastName = lastName.trim()
        InviteUser(firstName, lastName);
    }

    const InviteUser = (firstName, lastName) => {
        let formData = {
            "email": email,
            "first_name": firstName,
            "last_name": lastName,
        }
        let response = users_api.inviteUser(formData);
        response.then(
            response => {
                if(response.data.error) {
                    auth.logout()
                    setError(response.data.error)
                }
                else{
                    setSubpage(2)
                }
            }
        ).catch((err) => {
            setError("Invitation failed")
            console.log(err)
        })
    }

    const CheckData = () => {
        let names = name.split(" ")
        let nameCheck = !(names.length < 2)
        let emailCheck = !(!email)
        return [nameCheck, emailCheck].every(Boolean);
    }

    return (
        <>
            <div className="page-mask" />
            <div className="side-nav-pop-up-container">
                <div className="pop-up-close-container">
                    <img src={close} alt={"Close"} onClick={toggleMenu} className="link"/>
                </div>
                <div className="pop-up-title-container">
                    <div className="popup-title center">
                        Invite others to join Snapshot
                    </div>
                </div>
                {subpage && subpage === 1
                    ?
                    <>
                        <div className="pop-up-description-container">
                            <div className="popup-description center">
                                Make sure your loved ones are prepared for life's unexpected events -
                                invite them to join our platform today!
                            </div>
                        </div>
                        <form name="register-form" onSubmit={handleInvite}>
                            <div className="invite-others-form-container">
                                <div className="invite-others-form-element-container">
                                    <div className="invite-others-form-element-label">Full name</div>
                                    <input type="text" required
                                           onChange={(ev) => {setName(ev.target.value)}}/>
                                </div>

                                <div className="invite-others-form-element-container">
                                    <div className="invite-others-form-element-label">Email address</div>
                                    <input type="email" required
                                           onChange={(ev) => {setEmail(ev.target.value)}}/>
                                </div>
                            </div>
                            <div className="feedback-error">
                                {error && error}
                            </div>
                            <button type="submit" className="invite-others-form-button" disabled={!CheckData()}>
                                Invite
                            </button>
                        </form>
                    </>
                    :
                    <>
                        <div className="pop-up-description-container">
                            <div className="popup-description center">
                                Thank You for inviting someone to join our platform
                            </div>
                        </div>
                    </>
                }


            </div>
        </>
    )

}

export default InviteOthersPopUp
