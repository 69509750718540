import {useEffect, useState} from "react";

import dateUtils from "../utils/dateUtils";

import close from "../assets/close.webp"
import deleteImage from "../assets/delete.webp"
import bud_api from "../api/bud";

const AccountDetailsDialogue = (props) => {

    const toggleDetailsDialogue = props.toggleDialogue
    const refreshData = props.refreshData

    const [accountDetails, setAccountDetails] = useState()
    const [display, setDisplay] = useState()

    const [displayConfirmation, setDisplayConfirmation] = useState(false)

    useEffect(() => {
        setDisplay(props.display)
    }, [props.display])

    useEffect( () => {
        if (!props.account) return;
        setAccountDetails(props.account)
    }, [props.account])
    
    const handleToggleMenu = (ev) => {
        if (ev.target.id !== "toggle" && ev.target.id !== "remove") return;
        toggleDetailsDialogue();
    }

    const handleToggleConfirmationMenu = (ev) => {
        if (ev.target.id !== "toggle" && ev.target.id !== "remove") return;
        setDisplayConfirmation(!displayConfirmation);
    }

    const handleRemoveProvider = (ev) => {
        if (ev.target.id !== "remove") return;
        handleToggleMenu(ev);
        setDisplayConfirmation(true);
    }

    const handleConfirmationRemoveProvider = (ev) => {
        if (ev.target.id !== "remove") return;
        let provider = accountDetails.provider
        let response = bud_api.revokeProvider(provider);
        response.then(
            () => {
                refreshData()
                handleToggleConfirmationMenu(ev)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
            {display && display ?
                <div className="account-dialog-component">
                    <div className="account-dialog-title-container">
                        <div className="account-dialog-title-center-container">
                            <div className="bank-account-logo">
                                <img style={{borderRadius: "30px"}} src={accountDetails && accountDetails.logo}/>
                            </div>
                            <div className="bank-account-provider">
                                {accountDetails && accountDetails.provider}
                            </div>
                            <div className="account-dialog-close-container">
                                <button onClick={handleToggleMenu} id="toggle" className="account-dialog-close">
                                    <img src={close} id="toggle"/>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="account-dialog-body-container">
                        <div className="account-dialog-body-section">
                            <div className="account-dialog-label">
                                Account number
                            </div>
                            <div className="account-dialog-value">
                                {accountDetails && accountDetails.account_number}
                            </div>
                        </div>
                        <div className="account-dialog-body-section">
                            <div className="account-dialog-label">
                                Sort code
                            </div>
                            <div className="account-dialog-value">
                                {accountDetails && accountDetails.sort_code}
                            </div>
                        </div>
                        <div className="account-dialog-body-section">
                            <div className="account-dialog-label">
                                Last data retrieval
                            </div>
                            <div className="account-dialog-value">
                                {dateUtils.formatDate(accountDetails && accountDetails.updated_at)}
                            </div>
                        </div>
                    </div>

                    <div className="account-dialog-buttons-container">
                        <button className="account-dialog-delete-button" onClick={handleRemoveProvider}
                                id="remove">Remove Provider
                        </button>
                    </div>
                </div>
                :
                null
            }

            {displayConfirmation && displayConfirmation ?
                <>
                    <div className="delete-account-dialogue">
                        <div className="delete-account-dialogue-title-container">
                            <img src={deleteImage} alt="Trashcan" />
                            <div className="delete-account-dialogue-title">
                                Are you sure you want to remove this Bank Provider?
                            </div>
                        </div>
                        <div className="delete-account-dialogue-body-container">
                            <div className="delete-account-dialogue-body">
                                This will delete all account and transaction data.
                            </div>
                        </div>
                        <div className="delete-account-dialogue-buttons-container">
                            <button className="delete-account-dialogue-cancel-button"
                                    onClick={handleToggleConfirmationMenu} id="toggle">
                                Cancel
                            </button>
                            <button className="delete-account-dialogue-hide-button"
                                    onClick={handleConfirmationRemoveProvider} id="remove">
                                Remove provider
                            </button>
                        </div>
                    </div>
                </>
                :
                null
            }
        </>
    )
}

export default AccountDetailsDialogue