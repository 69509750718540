import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {sanitize} from "dompurify";

import public_api from "../../api/public";

import arrow from "../../assets/dark_arrow.webp"
import next_arrow from "../../assets/blog-card-arrow.webp"


const BlogArticleSubpage = (props) => {
    let navigate = useNavigate()

    const [article, setArticle] = useState()

    const [relatedArticles, setRelatedArticles] = useState()

    useEffect(() => {
        if (!props.link) return;
        getArticle()
        document.getElementById('blog-article-home-container').scroll(0,0)
    }, [props.link])

    const getArticle = () => {
        let response = public_api.getBlog(props.link);
        response.then(
            response => {
                let data = response.data.articles[0]
                setArticle(data)
                getRelatedArticles(data.category)
            }
        ).catch((err) => {
            console.log(err)
        })

    }

    const getRelatedArticles = (category) => {
        let response = public_api.getRelatedBlogs(category);
        response.then(
            response => {
                let data = response.data.articles;
                setRelatedArticles(data)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    const NavigateTo = (link) => {
        navigate(link)
    }

    return (
        <>
            <div className="blog-article-container">
                <div className="blog-article">
                    <div tabIndex="0" className="back-arrow-container link">
                        <img src={arrow} alt={"Back arrow"}
                             onClick={(ev) => {NavigateTo("/blog")}} />
                    </div>
                    <div className="blog-article-category">
                        {article && article.category}
                    </div>
                    <div className="blog-article-title-container">
                        <h1 className="blog-article-title">
                            {article && article.title}
                        </h1>
                    </div>

                    <div className="blog-article-image-container">
                        <img src={article && article.image} alt={"Hero Image"} />
                    </div>
                    <div className="blog-article-body-container">
                        {article && article.bodies && article.bodies.map((section, idx) => {
                            return (
                                <>
                                    <div className="blog-article-body"
                                         dangerouslySetInnerHTML={{__html: sanitize(section.content, {ADD_ATTR: ["target"]})}} />
                                </>
                            )
                        })}
                    </div>
                </div>
                <div className="blog-article-separator" />
                <div className="blog-article-cards-container">
                    <div className="row">
                        {relatedArticles && relatedArticles.map((blog, idx) => {
                            return (
                                <div className="col-sm-4">
                                    <Link to={"/article/" + blog.link} className="link">
                                        <div className="blog-article-card-container">
                                            <div className="blog-article-card-image-container">
                                                <img src={blog.image} alt={"Thumbnail Image"} />
                                            </div>
                                            <div className="blog-article-card-details-container">
                                                <div className="blog-article-card-category">
                                                    {blog.category}
                                                </div>
                                                <div className="blog-article-card-title-container">
                                                    <div className="blog-article-card-title">
                                                        {blog.title}
                                                    </div>
                                                    <div className="blog-article-card-arrow">
                                                        <img src={next_arrow} alt="Card Link Arrow"/>
                                                    </div>
                                                </div>
                                                <div className="blog-article-card-description">
                                                    {blog.description}
                                                </div>
                                            </div>

                                        </div>
                                    </Link>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogArticleSubpage