import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

import auth from "../../api/auth"

import NavBar from "../../components/NavBar";
import HomepageLanding from "../../components/home/HomepageLanding";
import HomepageFeatures from "../../components/home/HomepageFeatures";
import HomepageDidYouKnow from "../../components/home/HomepageDidYouKnow";
import HomepagePricing from "../../components/home/HomepagePricing";
import HomepageOurStory from "../../components/home/HomepageOurStory";
import HomepagePartnerships from "../../components/home/HomepagePartnerships";

import HomepageOurTech from "../../components/home/HomepageOurTech";
import HomepageGetStartedBanner from "../../components/home/HomepageGetStartedBanner";
import HomepageFAQs from "../../components/home/HomepageFAQs";
import HomepageContact from "../../components/home/HomepageContact";
import HomeFooter from "../../components/HomeFooter";
import HomeSingleReport from "../../components/home/HomeSingleReport";
import HomepageDynamicBanner from "../../components/home/HomepageDynamicBanner";

const Home = () => {

    const [urlSearch, setUrlSearch] = useSearchParams();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        let isAuth = auth.isAuthenticated()
        if (isAuth === true) window.location.replace("/dashboard")
        else setIsAuthenticated(isAuth);
    }, [])


    useEffect(() => {
        if (!urlSearch.get("page")) return
        MoveToSection(urlSearch.get("page"))
    }, [urlSearch.get("page")])


    const MoveToSection = (section) => {
        if (!section) return;
        setUrlSearch({})
        document.getElementById(section)?.scrollIntoView({behavior: 'smooth'})
    }

    return (
        <>
            <div className="home-page-container">
                <NavBar/>
                <HomepageLanding />
                <HomepageFeatures />
                <HomeSingleReport />
                <HomepageDidYouKnow />
                <HomepageDynamicBanner />
                <HomepagePricing />
                <HomepageOurStory />
                <HomepageOurTech />
                <HomepageGetStartedBanner />
                <HomepagePartnerships />
                <HomepageFAQs />
                <HomepageContact />
                <HomeFooter />
            </div>
        </>
    )
}

export default Home