
const HomepagePartnerships = () => {

    const MoveToContact = () => {
        document.getElementById("contact")?.scrollIntoView({behavior: 'smooth'})
    }

    return (
        <div className="home-page-partnerships-container">
            <a className="anchor" id="partnerships" />
            <div className="home-page-partnerships-title">
                Partnerships
            </div>
            <div className="home-page-partnerships-description">
                Join forces with Snapshot to offer a comprehensive digital legacy solution.
            </div>
            <div className="home-page-partnerships-card-container">
                <div className="home-page-partnership-card one">
                    <div className="home-page-partnership-card-title">
                        Businesses
                    </div>
                    <div className="home-page-partnership-card-description">
                        As an organizations, why not cover the costs of a Snapshot subscription for your employees or
                        customers.
                    </div>
                </div>
                <div className="home-page-partnership-card two">
                    <div className="home-page-partnership-card-title">
                        Corporate
                    </div>
                    <div className="home-page-partnership-card-description">
                        Why not add us to your portfolio of products to make your customers more ‘sticky’ or partner
                        with us to white label our platform.
                    </div>
                </div>
                <div className="home-page-partnership-card three">
                    <div className="home-page-partnership-card-title">
                        Affiliates
                    </div>
                    <div className="home-page-partnership-card-description">
                        Help us grow the platform and reach as many people as possible. Get in touch about our
                        affiliate programme.
                    </div>
                </div>
            </div>
            <div className="partnerships-get-in-touch-button-container">
                <div tabIndex="0" className="partnerships-get-in-touch-button link"
                     onClick={(ev) => {MoveToContact()}}>
                    Get in touch
                </div>
            </div>
        </div>
    )
}

export default HomepagePartnerships