import {useState} from "react";
import tick from "../assets/incomplete.webp";
import close from "../assets/close.webp";


const PlusUpgradeBanner = (props) => {


    const CreateCheckOutSession = props.createSession
    const toggleMenu = props.toggle
    const [yearly, setYearly] = useState(false)

    return (
        <div className="plus-subscription-banner-container">
            <div className="plus-subscription-access-container">
                <div className="plus-subscription-close">
                    <img src={close} alt={"Close"} onClick={toggleMenu} className="link"/>
                </div>
                <div className="plus-subscription-banner-heading-container">
                    <div className="plus-subscription-banner-title">
                        Access <span> Plus </span> features
                    </div>
                    <div className="plus-subscription-banner-description">
                        Take the first steps towards a more organised life.
                    </div>
                </div>
                <div className="plus-subscription-banner-content">
                    <div className="plus-subscription-features-content">
                        <div className="register-plus-features-container">
                            <div className="register-plus-feature">
                                <img src={tick} alt={"tick"}/>
                                <div className="register-plus-feature-text">
                                    Store unlimited items
                                </div>
                            </div>

                            <div className="register-plus-feature">
                                <img src={tick} alt={"tick"}/>
                                <div className="register-plus-feature-text">
                                    Link your bank accounts to track all regular payments
                                </div>
                            </div>

                            <div className="register-plus-feature">
                                <img src={tick} alt={"tick"}/>
                                <div className="register-plus-feature-text">
                                    Snapshot report of your life’s details
                                </div>
                            </div>

                            <div className="register-plus-feature">
                                <img src={tick} alt={"tick"}/>
                                <div className="register-plus-feature-text">
                                    Financial health reports
                                </div>
                            </div>

                            <div className="register-plus-feature">
                                <img src={tick} alt={"tick"}/>
                                <div className="register-plus-feature-text">
                                    Identity check for nominees on death
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="plus-subscription-type-content">
                        <div className="plus-subscription-type-title-container">
                            <div className="plus-subscription-type-price">
                                £2
                            </div>
                            <div className="plus-subscription-type-label">
                                / month  (or £20 per annum)
                            </div>
                        </div>
                        <div className="register-plus-monthly-container">
                            <div className={!yearly ? "register-period-radio selected" : "register-period-radio link"}
                                 onClick={(ev) => setYearly(false)} />
                            <div className="register-plus-type">
                                Monthly
                            </div>
                            <div className="register-plus-price-container">
                                <div className="register-plus-price">
                                    £2
                                </div>
                                <div className="register-plus-period">
                                    /month
                                </div>
                            </div>
                        </div>
                        <div className="plus-subscription-type-divider" />
                        <div className="register-plus-yearly-container">
                            <div className={yearly ? "register-period-radio selected" : "register-period-radio link"}
                                 onClick={(ev) => setYearly(true)} />
                            <div className="register-plus-type">
                                Yearly
                            </div>
                            <div className="register-plus-price-container">
                                <div className="register-plus-price">
                                    £20
                                </div>
                                <div className="register-plus-period">
                                    /year
                                </div>
                            </div>

                        </div>

                        <button className="plus-subscribe-button link" onClick={(ev) => {
                            if (yearly) CreateCheckOutSession("plus-yearly")
                            else CreateCheckOutSession("plus")
                        }}>
                            Subscribe
                        </button>

                        <div className="plus-subscription-cancel-anytime">
                            Cancel at any time.
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default PlusUpgradeBanner