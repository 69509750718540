import {useEffect, useState} from "react";
import documents_api from "../api/documents";
import deleteImage from "../assets/delete.webp";
import downloadDocument from "../assets/download-file-button.webp"
import currentFileImage from "../assets/current-file.webp"
import GenericDialog from "./GenericDialog";

const AddDocument = (props) => {

    const [documentID, setDocumentID] = useState()
    const [currentFile, setCurrentFile] = useState()
    const [fileType, setFileType] = useState()

    const [file, setFile] = useState()
    const [title, setTitle] = useState("");

    const [value, setValue] = useState("")
    const [policyNumber, setPolicyNumber] = useState("");
    const [renewal, setRenewal] = useState()

    const [description, setDescription] = useState("");

    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const okButton = [{"text":"OK", "id": "1"}];
    const [error, setError] = useState([]);
    const [heading, setHeading] = useState("");

    const documentType = props.documentType
    const documentTitle = props.documentTitle

    const refreshDocuments = props.refreshDocuments
    const hideDocument = props.hideDocument

    const [displayConfirmation, setDisplayConfirmation] = useState(false)

    const filetype_to_base64 = {
        "jpg": "image/jpg",
        "jpeg": "image/jpeg",
        "png": "image/png",
        "gif": "image/gif",
        // "svg": "image/svg+xml",

        "json": "application/json",
        "txt": "text/plain",
        "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "pdf": "application/pdf"
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!documentID) AddDocument();
        else UpdateDocument();
        return true;
    }

    const handleDelete = (event) => {
        if (event.target.id !== "delete") return;
        setDisplayConfirmation(true)
    }

    const handleCancel = (event) => {
        if (event.target.id !== "cancel") return;
        Cancel()
        hideDocument()
    }

    const handleToggleConfirmationMenu = (ev) => {
        if (ev.target.id !== "toggle" && ev.target.id !== "remove") return;
        setDisplayConfirmation(!displayConfirmation);
    }

    const handleConfirmationDeleteDocument = (ev) => {
        if (ev.target.id !== "delete") return;
        handleToggleConfirmationMenu(ev);
        DeleteDocument()
    }

    const handleUploadClick = () => {
        // 👇 We redirect the click event onto the hidden input element
        document.getElementById("file")?.click();
    };

    const handleOKDialogResult = () => {
        setShowErrorDialog(false);
    }

    const handleFileChange = (ev) => {
        if (!ev.target.files) {
            return;
        }
        setFile(ev.target.files[0]);
    };

    const DownloadDocument = () => {
        if (!currentFile || (!documentID && documentID !== 0) || !fileType) return
        let formData = new FormData
        formData.append('documentId', documentID)
        formData.append('file', currentFile)
        let base_64 = 'data:' + filetype_to_base64[fileType] + ';base64,' + currentFile
        let a = document.createElement("a");
        a.href = base_64;
        a.download = title + "." + fileType;
        a.click();
    }

    const AddDocument = () => {
        let formData = new FormData
        formData.append('file', file);
        formData.append('type', documentType);
        formData.append('title', title);
        formData.append('description', description);
        formData.append('value', value);
        formData.append('policy_number', policyNumber);
        formData.append('renewal', renewal);

        let response = documents_api.postDocument(formData);
        response.then(
            response => {
                if (response.data && response.data.error){
                    if (response.data.error === "subscription check failed") {
                        window.location.assign("/dashboard?reason=membership");
                    }
                    else {
                        setHeading("FAILED");
                        let lines = [];
                        lines.push(response.data.error);
                        setError(lines);
                        setShowErrorDialog(true);
                    }
                }
                else {
                    refreshDocuments()
                    hideDocument()
                }

            }
        ).catch((err) => {
            setHeading("FAILED");
            setError(["Failed to upload document."]);
            setShowErrorDialog(true);
            console.log(err)
        })
    }

    const UpdateDocument = () => {
        let formData = {
            "documentId": documentID,
            "type": documentType,
            "title": title,
            "description": description,
            "value": value,
            "policy_number": policyNumber,
            "renewal" : renewal
        }
        let response = documents_api.editDocument(formData);
        response.then(
            response => {
                if (response.data && response.data.error){
                    setHeading("FAILED");
                    let lines = [];
                    lines.push(response.data.error);
                    setError(lines);
                    setShowErrorDialog(true);
                }
                else {
                    refreshDocuments()
                    hideDocument()
                }

            }
        ).catch((err) => {
            setHeading("FAILED");
            setError(["Failed to update document."]);
            setShowErrorDialog(true);
            console.log(err)
        })
    }

    const DeleteDocument = () => {
        let response = documents_api.deleteDocument(documentID);
        response.then(
            response => {
                refreshDocuments()
                hideDocument()
            }
        ).catch((err) => {
            setHeading("FAILED");
            setError(["Failed to delete document."]);
            setShowErrorDialog(true);
            console.log(err)
        })
    }

    const Cancel = () => {
        setDocumentID(null)
        setTitle(null)
        setDescription(null)
        setValue(null)
        setRenewal(null)
        setPolicyNumber(null)
    }

    useEffect(() => {
        if (props.document){
            setDocumentID(props.document.id)
            setTitle(props.document.title)
            setDescription(props.document.description)
            setValue(props.document.value)
            setRenewal(props.document.renewal)
            setPolicyNumber(props.document.policy_number)
            setCurrentFile(props.document.file)
            setFileType(props.document.file_type)
            setFile(props.document.file)
        }
    }, [props.document])

    return (
        <>
            <div className="add-document-container">
                <div className="add-document-title">
                    {documentID && documentID ? "Update " + (documentTitle && documentTitle ? documentTitle : "") : "New " + (documentTitle && documentTitle ? documentTitle : "") + " document"}
                </div>
                <div className="add-document-form-container">
                    <form name="add-document-form" onSubmit={handleSubmit}>
                        <div className="add-document-form-upload-container">
                            {documentID && documentID ?
                                <>
                                    <div className="current-file-image-container">
                                        <img src={currentFileImage} />
                                    </div>
                                    <div className="current-file-name">
                                        {title && fileType && title + "." + fileType}
                                    </div>
                                    <button onClick={DownloadDocument} className="download-file-button link">
                                        <img src={downloadDocument} alt="Download"/>
                                    </button>
                                </>
                                :
                                <button onClick={handleUploadClick} className="upload-file-input-button">
                                    {file ? file.name : 'Select a file to upload or drop your file here'}
                                </button>
                            }
                            <input id="file" type="file" onChange={handleFileChange} style={{display: "none"}}/>
                        </div>
                        <div className="add-document-form-element-container">
                            <label>Document title</label>
                            <input id="title" type="text" value={title} onChange={(ev) => {
                                setTitle(ev.target.value)
                            }} required />
                        </div>
                        <div className="add-document-form-row-container col">
                            <div className="add-document-form-element-container-multiple col-sm-6">
                                <label>Value</label>
                                <input id="value" type="text" value={value} onChange={(ev) => {
                                    setValue(ev.target.value) }}/>
                            </div>
                            <div className="add-document-form-element-container-multiple col-sm-5">
                                <label>Policy number</label>
                                <input id="policyNumber" type="text" value={policyNumber} onChange={(ev) => {
                                    setPolicyNumber(ev.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className="add-document-form-description-container">
                            <label>Additional information</label>
                            <textarea id="description" value={description} onChange={(ev) => {
                                setDescription(ev.target.value)
                            }}/>
                        </div>
                        <div className="add-document-buttons-container">
                            <div className="add-document-submit-button-container">
                                <input type="submit" value={documentID && documentID ? "Save changes" : "Add document"}
                                       className="add-document-button"
                                       disabled={!file} />
                            </div>
                            {documentID && documentID ?
                                <div className="add-document-delete-button link" id="delete" onClick={handleDelete}>
                                    Delete document
                                </div>
                                :
                                <div className="add-document-cancel-button link" onClick={handleCancel} id="cancel" > Cancel </div>
                            }
                        </div>
                    </form>
                </div>
                {displayConfirmation && displayConfirmation ?
                    <>
                        <div className="delete-document-dialogue">
                            <div className="delete-document-dialogue-title-container">
                                <img src={deleteImage} />
                                <div className="delete-document-dialogue-title">
                                    Are you sure you want to remove this document?
                                </div>
                            </div>
                            <div className="delete-document-dialogue-body-container">
                                <div className="delete-document-dialogue-body">
                                    This action cannot be undone.
                                </div>
                            </div>
                            <div className="delete-document-dialogue-buttons-container">
                                <button className="delete-document-dialogue-cancel-button"
                                        onClick={handleToggleConfirmationMenu} id="toggle">
                                    Cancel
                                </button>
                                <button className="delete-document-dialogue-hide-button"
                                        onClick={handleConfirmationDeleteDocument} id="delete">
                                    Remove document
                                </button>
                            </div>
                        </div>
                    </>
                    :
                    null
                }
            </div>
            {showErrorDialog ?
                <GenericDialog title="Document Error" text={error} heading={heading} buttons={okButton}
                               result={handleOKDialogResult.bind(this)} /> : null }
        </>

    )
}

export default AddDocument