import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import SideNav from "../../components/SideNav";
import auth from "../../api/auth";
import GuideArticleSubpage from "../../components/subpages/GuideArticleSubpage";

const GuideArticle = () => {
    let params = useParams();

    const [link, setLink] = useState()

    useEffect(() => {
        let authenticated = auth.isAuthenticated()
        if (!authenticated) window.location.assign("/login");
    }, [])

    useEffect(() => {
        setLink(params.link)
    }, [params.link])


    return (
        <>
            <div className="page-container" id="scroll-container">
                <SideNav/>
                <div className="guide-subpage-container">
                    <GuideArticleSubpage link={link} />
                </div>
            </div>
        </>
    )
}

export default GuideArticle