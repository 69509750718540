import {Link} from "react-router-dom";
import {useEffect, useState} from "react";

import public_api from "../../api/public"

import arrow from "../../assets/blog-card-arrow.webp"


const BlogSubpage = () => {

    const [blogs, setBlogs] = useState()

    useEffect(() => {
        getArticles()
    }, [])

    const getArticles = () => {
        let response = public_api.getBlogs();
        response.then(
            response => {
                setBlogs(response.data.articles)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
            <div className="blog-page-container">
                <div className="blog-heading-container">
                    <div className="blog-heading">
                        Snapshot Blog
                    </div>
                    <div className="blog-description">
                        Weekly updates on things you can do to plan a secure future and gain peace of mind.
                    </div>
                </div>
                <div className="blog-articles-container">
                    <div className="row">
                        {blogs && blogs.map((blog, idx) => {
                            return (
                                <div className="col-sm-4">
                                    <Link to={"/article/" + blog.link} className="link">
                                        <div className="blog-article-card-container">
                                            <div className="blog-article-card-image-container">
                                                <img src={blog.image} alt={"Thumbnail Image"} />
                                            </div>
                                            <div className="blog-article-card-details-container">
                                                <div className="blog-article-card-category">
                                                    {blog.category}
                                                </div>
                                                <div className="blog-article-card-title-container">
                                                    <div className="blog-article-card-title">
                                                        {blog.title}
                                                    </div>
                                                    <div className="blog-article-card-arrow">
                                                        <img src={arrow} alt="Card Link Arrow"/>
                                                    </div>
                                                </div>
                                                <div className="blog-article-card-description">
                                                    {blog.description}
                                                </div>
                                            </div>

                                        </div>
                                    </Link>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogSubpage