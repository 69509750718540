import SideNav from "../../components/SideNav";
import { useEffect } from "react";
import auth from "../../api/auth";
import GuideSubpage from "../../components/subpages/GuideSubpage";

const KnowledgeHub = () => {

    useEffect(() => {
        let authenticated = auth.isAuthenticated()
        if (!authenticated) window.location.assign("/login");
    }, [])

    return (
        <>
            <div className="page-container" id="scroll-container">
                <SideNav />
                <div className="knowledge-hub-subpage-container">
                    <GuideSubpage />
                </div>
            </div>
        </>
    )
}

export default KnowledgeHub