import {useNavigate} from "react-router-dom";

import safe from "../../assets/safe.webp";
import folders from "../../assets/folders.webp";
import head from "../../assets/head.webp";
import body from "../../assets/body.webp";

const HomeSingleReport = () => {
    const navigate = useNavigate()

    return (
        <>
            <div className="home-single-report-container">
                <div className="home-single-report-content">
                    <div className="home-single-report-text-block col">
                        <div className="home-single-report-title">A single report</div>
                        <div className="home-single-report-text">
                            Create a single report, a live ‘Snapshot’ of your affairs on that day.
                            A summary of everything you have.
                        </div>
                        <div tabIndex="0" className="home-single-report-button link"
                             onClick={(ev) => {navigate("/register")}}>
                            Get started
                        </div>
                    </div>
                    <div className="home-single-report-card-container col">
                        <div className="home-single-report-card">
                            <div className="home-single-report-card-logo">
                                <img src={safe} alt="report safe" className="safe"/>
                            </div>
                            <div className="home-single-report-card-text-block">
                                <div className="home-single-report-card-title">BANKING</div>
                                <div className="home-single-report-card-text">Link your bank accounts to track regular
                                    payments, subscriptions, direct debits, and standing orders.</div>
                            </div>
                        </div>
                        <div className="home-single-report-card">
                            <div className="home-single-report-card-logo">
                                <img src={folders} alt="report folders" className="folders"/>
                            </div>
                            <div className="home-single-report-card-text-block">
                                <div className="home-single-report-card-title">DOCUMENTS</div>
                                <div className="home-single-report-card-text">Upload important documents you want to
                                    keep safe, like your life insurance, your will, or your pension information.</div>
                            </div>
                        </div>
                        <div className="home-single-report-card">
                            <div className="home-single-report-card-logo">
                                <img src={head} alt="report head" className="head"/>
                                <img src={body} alt="report body" className="body"/>
                            </div>
                            <div className="home-single-report-card-text-block">
                                <div className="home-single-report-card-title">CONTACTS</div>
                                <div className="home-single-report-card-text">Put all your important contacts in one
                                    place, like your next of kin, your solicitor and your accountant.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeSingleReport