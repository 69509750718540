import padlock from "../../assets/padlock.webp";
import shield from "../../assets/shield.webp";
import connectivity from "../../assets/connectivity.webp";

const HomepageOurTech = () => {
    return (
        <div className="home-page-our-technology-container">
            <div className="home-page-our-technology-title">
                Security
            </div>
            <div className="home-page-our-technology-description subtitle">
                At Snapshot, we take security seriously.
            </div>
            <div className="home-page-our-technology-description">
                We encrypt all your data so your information is completely private and secure.
                Only you and your trusted contacts have access to it - not even we can view it!
            </div>
            <div className="home-page-technology-cards-container">
                <div className="home-page-technology-card with-border">
                    <div className="home-page-technology-card-image">
                        <img src={padlock} alt="padlock image"/>
                    </div>
                    <div className="home-page-technology-card-title">Secure Login</div>
                    <div className="home-page-technology-card-text">We verify every account at registration and offer Two
                        Factor Authentication so we can always be sure it's you.</div>
                </div>
                <div className="home-page-technology-card with-border">
                    <div className="home-page-technology-card-image">
                        <img src={shield} alt="shield image"/>
                    </div>
                    <div className="home-page-technology-card-title">Encrypted data and files</div>
                    <div className="home-page-technology-card-text">Whatever you type in or upload is encrypted before
                        it’s stored. Only you or your trusted contacts can decrypt it. Even Snapshot can’t see your data.</div>
                </div>
                <div className="home-page-technology-card">
                    <div className="home-page-technology-card-image">
                        <img src={connectivity} alt="connectivity image"/>
                    </div>
                    <div className="home-page-technology-card-title">Connect securely</div>
                    <div className="home-page-technology-card-text">Only HTTPS secure connections between your browser
                        and our servers are supported, so no-one in between can see your data across the Internet.</div>
                </div>
            </div>
        </div>
    )
}

export default HomepageOurTech