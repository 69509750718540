import {useEffect, useState} from "react";

import restore from "../assets/refresh.webp"
import GenericDialog from "./GenericDialog";

const TransactionHideConfirmation = (props) => {

    const closeDisplay = props.closeDisplay
    const unhideTransaction = props.unhideTransaction

    const info = [
        "Your next of kin will see this transaction added to the report.",
        "If you change your mind, you can add undo this action in your Regular outgoings under Banking."
    ]
    const heading = "Are you sure you want to restore this outgoing?"
    const buttons = [{"text":"Cancel", "id": "1"},{"text":"Restore outgoing", "id": "2"}]

    const [display, setDisplay] = useState()

    const handleDialogResult = (selection) => {
        if (selection === "1")
            closeDisplay();
        if (selection === "2")
            unhideTransaction();
    };

    useEffect(() => {
        setDisplay(props.display)
    }, [props.display])
    
    return (
        <>
            {display ?
                <GenericDialog title="Restore Outgoings" text={info} heading={heading} icon={restore}
                               buttons={buttons} result={handleDialogResult.bind(this)}
                /> : null }
        </>
    )

}

export default TransactionHideConfirmation