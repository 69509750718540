import http_api from "./http/http_api";

const getContacts = () => http_api.get("/44968d3ddb237d5c8c9320019/contacts");
const postContact = (data) => http_api.post("/44968d3ddb237d5c8c9320019/add/contacts", data);

const editContact = (data) => http_api.put("/44968d3ddb237d5c8c9320019/update/contact", data);

const deleteContact = (contact_id) => http_api.delete("/44968d3ddb237d5c8c9320019/delete/contact/" + contact_id);

const getExecutors = () => http_api.get("/44968d3ddb237d5c8c9320019/executors");


export default {
    getContacts,
    postContact,
    editContact,
    deleteContact,
    getExecutors
}