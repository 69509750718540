import http_api from "./http/http_api";


const getAccounts = () => http_api.get("/44968d3ddb237d5c8c9320019/account")
// const getRegularTransactions = () => http_api.get("/44968d3ddb237d5c8c9320019/regular-transactions");
const postRegularTransaction = (body) => http_api.post("/44968d3ddb237d5c8c9320019/transaction", body);

// * reference: POST {{baseUrl}}/44968d3ddb237d5c8c9320019/b/transactions/list

const urlPrefix = process.env.NODE_ENV === 'development' ? '/bud' : '/44968d3ddb237d5c8c9320019/b'
const getAllTransactions = (offset, limit, transactionTypes=['transaction'], showHidden=false) => http_api.post(`${urlPrefix}/transactions/list`, {offset, limit, transactionTypes, showHidden});
const updateTransactions = (body) => http_api.post(`${urlPrefix}/transactions/update`, body);

const hideTransaction = ({userId, transactionId, transactionType='transaction', isHidden=true}) => http_api.post(`${urlPrefix}/transaction/update`, {userId, transactionId, transactionType, isHidden });

// const hideTransaction = (transaction_label) => http_api.post("/44968d3ddb237d5c8c9320019/hidden-transactions", {
//     "label": transaction_label
// })
const unhideTransaction = ({userId, transactionId, transactionType='transaction', isHidden=false}) => http_api.post(`${urlPrefix}/transaction/update`, {userId, transactionId, transactionType, isHidden })
// const unhideTransaction = (transaction_label) => http_api.delete("/44968d3ddb237d5c8c9320019/hidden-transactions/" + transaction_label)

const getHiddenTransactions = (offset=0, limit=10000, transactionTypes=['transaction'], showHidden=true) => http_api.post(`${urlPrefix}/transactions/list`, {offset, limit, transactionTypes, showHidden});

export default {
    getAccounts,
    // getRegularTransactions,
    postRegularTransaction,
    hideTransaction,
    getHiddenTransactions,
    unhideTransaction,
    getAllTransactions,
    updateTransactions
}