import axios from "axios";
import auth from "../auth";

// API LOCATION
let baseUrl = process.env.REACT_APP_API + "/api/";

// * Test API location
// const  baseUrl = "http://0.0.0.0:8000/" //  transactions/list"; // for some reason http://0.0.0.0:8000 stopped working, now use localhost

if(process.env.NODE_ENV === 'development') {
    baseUrl = "http://localhost:8000/"
}

let api = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

// * Test endpoint interceptors; userId (and transactionType) should come from the requesting component
if(process.env.NODE_ENV === 'development') {
const testEndpoint = /\/transactions\/list$/
api.interceptors.request.use(function (config) {
        if(testEndpoint.test(config.url)) {
        config.data = {
            ...config.data,
            userId: 6,
    }
}
    return config;
}, function (error) {
    return Promise.reject(error);
});
}
api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const statusCode = error.response ? error.response.status : null;
    // go to login if error is a 401
    if (statusCode === 401) {
        localStorage.removeItem("userState");
        localStorage.removeItem("budState");
        auth.logout("/login")
    }
    return Promise.reject(error);
})

export default api;