import {useNavigate} from "react-router-dom";
import {useState} from "react";
import sanctum_api from "../../api/csrf";
import users_api from "../../api/users";

import LoginRegisterSideImage from "../../components/LoginRegisterSideImage";

const ForgotPassword = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const handleSend = (ev) => {
        ev.preventDefault();
        SendRequest();
        return true;
    }

    const SendRequest = () => {
        let token_response = sanctum_api.getToken();
        token_response.then(
            () => {
                let formData = {
                    "email": email,
                    "apiKey": process.env.REACT_APP_RESET_PASSWORD_API_KEY
                }
                let response = users_api.forgotPassword(formData);
                response.then(
                    response => {
                        if(response.data.error) {
                            setError(response.data.error)
                        }
                        else{
                            setMessage("Check your email inbox for a reset link.")
                        }

                    }
                ).catch((err) => {
                    console.log(err);
                    setError("Sending reset email failed.");
                })
            }
        ).catch((err) => {
            console.log(err);
            setError("Sending reset email failed.");
        })
    }

    return (
        <>
            <div className="login-page-container">
                <LoginRegisterSideImage />
                <div className="login-page-form-container">
                    <form name="login-form" onSubmit={handleSend}>
                        <div className="login-page-title">
                            Forgotten password
                        </div>
                        <div className="login-page-description">
                            A link will be sent to your email address.
                        </div>
                        <div className="login-page-subtitle">
                            Enter the email address below.
                        </div>
                        <div className="login-page-inputs-container">
                            <div className="login-form-element-container">
                                <div className="login-form-element-label">Email address</div>
                                <input type="email" required
                                       onChange={(ev) => {setEmail(ev.target.value)}}/>
                            </div>
                            <div className="login-message">
                                {message && message}
                            </div>
                            <div className="login-error-message">
                                {error && error}
                            </div>
                        </div>
                        <div className="login-form-submit-container">
                            <input type="submit" value="Send" />
                        </div>
                        <div className="login-form-info-container">
                            <div className="login-page-no-account">
                                Remembered password?
                                <div tabIndex="0" className="register-now-link link" onClick={(ev) => navigate("/login")}>
                                    Login
                                </div>
                            </div>
                            <div className="login-page-contact-info">
                                support@snapshot.com
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword