import RegisterPageProgress from "../RegisterPageProgress";
import {useNavigate} from "react-router-dom";

const RegisterComplete = (props) => {

    const navigate = useNavigate()

    const handleNext = (event) => {
        event.preventDefault();
        navigate("/dashboard")
    }


    return (
        <div className="register-subpage-container" style={!props.show ? {display: "none"} : {}}>
            <RegisterPageProgress page={props.page ? props.page : 0} plus={props.plus} />
            <div className="register-page-subpage-container" >
                <form name="register-form" className="register-form" onSubmit={handleNext}>
                    <div className="register-page-title">
                        Account setup
                    </div>
                    <div className="register-page-subtitle two-factor">
                        Setup complete!
                    </div>
                    <div className="register-page-description two-factor">
                        Your account setup is now complete. Continue to start getting organised with Snapshot.
                    </div>
                    <div className="two-factor-button-container">
                        <input type="submit" value="Continue" className="verify-submit-button link"/>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RegisterComplete;