import RegisterPageProgress from "../RegisterPageProgress";
import {useEffect, useState} from "react";
import sharing_api from "../../api/sharing";

const RegisterNextOfKin = (props) => {

    const [accessNow, setAccessNow] = useState(false);
    const [nextOfKinEmail, setNextOfKinEmail] = useState()

    const [error, setError] = useState("")

    const nextSubpage = props.next

    useEffect(() => {
        if (!props.nextOfKinEmail) return;
        setNextOfKinEmail(props.nextOfKinEmail)
    }, [props.nextOfKinEmail])

    const handleNext = (event) => {
        event.preventDefault();
        if (accessNow) GiveAccessNow()
        else nextSubpage()
    }

    const GiveAccessNow = () => {
        let response = sharing_api.addSharingByEmail(nextOfKinEmail);
        response.then(
            () => {
                nextSubpage()
            }
        ).catch((err) => {
            setError("User adding to shared failed - " +
                "ensure they are a user and you have not already shared with them")
        })
    }

    return (
        <div className="register-subpage-container" style={!props.show ? {display: "none"} : {}}>
            <RegisterPageProgress page={props.page ? props.page : 0} />
            <div className="register-page-subpage-container" >
                <form name="register-form" onSubmit={handleNext}>
                    <div className="register-page-title">
                    Account setup
                </div>
                    <div className="register-page-subtitle two-factor">
                        Give access now or later
                    </div>
                    <div className="register-page-description two-factor">
                        You're in charge. Decide whether to give access to your nxt of kin or trusted individual
                        now or later. We'll keep everything safe until then.
                    </div>

                    <div className="register-page-inputs-container">

                        <div className="register-access-now-container">
                            <input type="checkbox" className="register-access-now-tick" onChange={ (ev) =>
                            {setAccessNow(ev.target.checked)}} />
                            <div className="register-access-now-label">
                                I want my next of kin to have access to my report now
                            </div>
                        </div>

                        <div className="register-error-message">
                            {error && error}
                        </div>
                        <div className="register-page-description two-factor">
                            If you don't tick this box, they will only have access when you're gone.
                        </div>
                    </div>

                    <div className="two-factor-button-container">
                        <input type="submit" value="Next" className="verify-submit-button link"/>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RegisterNextOfKin