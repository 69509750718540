import NavBar from "../../components/NavBar";
import HomeFooter from "../../components/HomeFooter";
import BlogSubpage from "../../components/subpages/BlogSubpage";

const Blog = () => {

    return (
        <>
            <div className="home-page-container">
                <NavBar/>
                <BlogSubpage />
                <HomeFooter />
            </div>
        </>
    )
}

export default Blog